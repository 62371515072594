﻿// COMPONENT.CONTACT-PAGE

@use "sass:math";

$contact-page-bp: "large";

// ### INTRO LAYOUT
.c-contact-page__layout {
	@include control;
	@include bp($contact-page-bp) {
		@include flex-extend;
		margin-left: -$hsu;
	}
}

.c-contact-page__header {
	.c-contact-page__heading {
		margin: 0;
	}
}

.c-contact-page__layout-details,
.c-contact-page__layout-form {
	@include bp($contact-page-bp) {
		padding-left: $hsu;
	}
}

.c-contact-page__layout-details {
	@include bp($contact-page-bp) {
		flex-grow: 1;
	}

	.c-contact-page__layout--no-form & {
		display: flex;
		flex-wrap: wrap;

		margin-left: -$bsu;
		margin-top: -$bsu;

		.c-contact-page__lede,
		.c-contact-page__person-list {
			padding-left: $bsu;
			padding-top: $bsu;
		}

		.c-contact-page__lede {
			width: 100%;
		}

		.c-contact-page__person-list {
			width: 100%;
			@include bp("medium") {
				width: percentage(calc(1 / 2));
			}
			@include bp("large") {
				width: percentage(calc(1 / 3));
			}
		}
	}
}

.c-contact-page__layout-form {
	@include bp-below($contact-page-bp) {
		margin-top: $lsu;
		margin-top: var(--control-gap);
	}
	@include bp($contact-page-bp) {
		flex-shrink: 0;
		width: 50%;
	}
}

.c-contact-page__lede {
	display: block;
	margin-bottom: $lsu;

	@include font-base-18;
}

.c-contact-page__map {
	width: 100%;
	min-height: 340px;
}

// ### PERSON LIST
.c-contact-page__person-list {
	display: block;
	margin-top: $lsu;
}

.c-contact-page__person-heading {
	@include font-heading-20b;
	margin-bottom: $bsu;
}

.c-contact-page__person-name {
	@include font-base-16;
	display: block;
}

.c-contact-page__person-position {
	@include font-base-16;
	display: block;
	margin-bottom: $bsu;
}

// ### DETAIL LIST
.c-contact-page__detail-list {
	@include list-reset;
}

.c-contact-page__detail-list-item {
	@include flex-extend;
	margin-bottom: $bsu;
}

.c-contact-page__detail-list-icon {
	flex-shrink: 0;

	@include circle(32px);
	font-size: 14px;
	border: 1px solid currentColor;

	display: inline-flex;
	align-items: center;
	justify-content: center;

	margin-right: $ssu;
}

.c-contact-page__detail-list-content {
	flex-grow: 1;
	// Align with icon
	padding-top: 6px;

	a {
		display: inline-block;
		padding-bottom: $ssu;

		@include link-fancy;
	}

	> :last-child {
		margin-bottom: 0;
	}
}

// ### LOCATION ITEMS
.c-contact-page__locations-heading {
	@include control__heading;
}

.c-contact-page__location-list {
	@include list-reset;
}

.c-contact-page__location {
	padding-top: $lsu;
	&:first-child {
		padding-top: 0;
	}

	@include bp($contact-page-bp) {
		@include flex-extend;
	}

	&:nth-child(2n) {
		flex-direction: row-reverse;
	}
}

.c-contact-page__location-body {
	
	padding: $bsu;

	@include bp($contact-page-bp) {
		padding: $lsu;
		flex-grow: 1;
	}
}

.c-contact-page__location-heading {
	@include font-heading-20b;
	margin-bottom: $bsu;
}

.c-contact-page__location-map-container {
	@include bp($contact-page-bp) {
		flex-shrink: 0;
		width: 50%;
		margin: $lsu 0;
	}
}