﻿// CONTROL.TABS

.c-tabs {}

.c-tabs__list {
    padding: 0;
    margin: 0 (-$bsu);
    list-style: none;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    flex-direction: row;
    justify-content: center;
    margin-bottom: $lsu;
    gap: 2px;
    background-color: var(--primary-b);

    @include bp("medium") {
        transform: translate(0, -50%);
        border-radius: 5px;
        overflow: hidden;
        margin: 0;
    }
}

.c-tabs__link {
    
    background-color: var(--primary-a);
    color: var(--primary-b);
    border-radius: 0;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: ($ssu * 1.5);


    &:hover,
    &:focus {
        background-color: var(--link--hover);
        color: var(--primary-a);
    }


    &[aria-current="true"] {
        background-color: var(--secondary-b);
        color: var(--secondary-a);
    }

    @include bp("medium") {
        padding: $bsu;
    }

    @include bp("large") {
    }

    @include bp("extra-large") {
    }
}

.c-tabs__link-text {
    display: block;
    margin-bottom: 0;
    @include font-heading-12;

    @include bp("medium") {
        @include font-heading-14;
    }

    &.no-mi {
        @include bp("medium") {
            @include font-base-18b;
        }

        @include bp-below("medium") {
            @include font-base-14b;
        }
    }

    @include bp-below("medium") {
        margin: 0 auto;
        width: 0;
        display: none;
        overflow: hidden;

        .c-tabs__link[aria-current="true"] &,
        .c-tabs__link:hover &,
        .c-tabs__link:focus & {
            width: auto;
            display: block;
        }
    }
}

.c-tabs__link-text-mi {


    @include bp("medium") {
        @include font-base-18b;
    }

    @include bp-below("medium") {
        @include font-base-14b;
        margin: $ssu auto;
        width: 0;
        display: none;
        overflow: hidden;

        .c-tabs__link[aria-current="true"] &,
        .c-tabs__link:hover &,
        .c-tabs__link:focus & {
            width: auto;
            display: block;
        }
    }
}

.c-tabs__icon {
    font-size: 25px;
    margin-bottom: $ssu;

    @include bp("medium") {
        font-size: 40px;
    }
}

.c-tabs__content {
    display: none;

    &[aria-current=true] {
        display: block;
    }
}