﻿// COMPONENT.GOLDEN-TRIANGLE
@use "sass:math";

.c-golden-triangle {
	//height: 100vh;
	margin-bottom: $lsu;

	@include bp("large") {
		margin-bottom: ($hsu * 4);
	}

	position: relative;
}

.c-golden-triangle__layout {
	width: 100%;
	height: 100%;
	padding-top: ($hsu * 2);
	.c-content-type-ruakurainlandporthomepage & {
		padding-top: $hsu;
	}
	padding-bottom: $lsu;

	@include bp("medium") {
		padding-top: $hsu;
	}

	@include bp("large") {
		position: absolute;
		top: 0;
	}
}

.c-golden-triangle__content-wrapper {
	@include layout-wrapper;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	padding-top: $lsu;

	@include bp("large") {
		padding-top: ($hsu * 2);
		.c-content-type-ruakurainlandporthomepage & {
			padding-top: $hsu * 1.5;
		}
	}
}

.c-golden-triangle__figure {
	@include layout-wrapper;
	max-width: 1600px;

	@include bp("large") {
		padding-top: ($hsu * 2);
		.c-content-type-ruakurainlandporthomepage & {
			padding-top: $hsu * 1.5;
		}
	}
}

.c-golden-triangle__image {
	width: 100%;

	&.c-golden-triangle__image-mobile {
		display: block;

		@include bp("medium") {
			display: none;
		}
	}

	&.c-golden-triangle__image-tablet {
		display: none;

		@include bp("medium") {
			display: block;
		}

		@include bp("large") {
			display: none;
		}
	}

	&.c-golden-triangle__image-desktop {
		display: none;

		@include bp("large") {
			display: block;
		}
	}
}

.c-golden-triangle__content-top {

	@include bp("medium") {
		grid-template-columns: repeat(1, 1fr);
	}

	@include bp("large") {
		grid-template-columns: repeat(2, 1fr);
	}
}

.c-golden-triangle__heading {
	@include font-heading-36b;
	margin-top: $ssu;

	@include bp("extra-large") {
		@include font-heading-90b;
		line-height: 1;
	}
}

.c-golden-triangle__heading-mi {
	display: block;
	@include font-heading-18b;

	@include bp("large") {
		.c-content-type-ruakurainlandporthomepage & {
			// Align this heading with the right columns' text
			// Assume logos reach maximum height of 150px, + 20px margin below
			margin-top: 170px;
		}
	}
}

.c-golden-triangle__logo-list {
	@include list-reset;
	display: flex;

	margin-left: -$lsu;
	margin-bottom: $bsu;

	max-width: 550px;
}

.c-golden-triangle__logo-list--mobile {
	@include bp("large") {
		display: none;
	}
}
.c-golden-triangle__logo-list--desktop {
	@include bp-below("large") {
		display: none;
	}
}

.c-golden-triangle__logo-item {
	padding-left: $lsu;
}

.c-golden-triangle__logo-link {
	display: block;
}

.c-golden-triangle__logo {
	display: block;
	max-width: 100%;
	max-height: 100px;
	@include bp("medium") {
		max-height: 150px;
	}
}

.c-golden-triangle__body {
	@include font-base-16b;
	line-height: 24px;

	@include bp("extra-large") {
		@include font-base-20b;
		line-height: 36px;
	}
}

.c-golden-triangle__link-wrapper {
	@include flex-extend;
}

.c-golden-triangle__link {
	display: inline-flex;
	align-items: center;
	padding-top: $tsu;
	padding-bottom: $ssu;
	margin-top: #{$ssu - $tsu};

	@include font-heading-14b;

	@include bp($latest-insights-bp) {
		@include font-heading-16b;
	}

	margin-right: $lsu;

	&:last-child {
		margin-right: 0;
	}

	&::after {
		@include font-icon;
		content: $iconf-arrow-right;
		display: inline-block;
		margin-left: $ssu;
	}
}

$bottomImgWidth: 300px;

.c-golden-triangle__content-bottom {
	display: none;

	@include bp("large") {
		display: block;
		position: absolute;
		margin-top: auto;
		bottom: -#{ calc($bottomImgWidth / 2) };
	}

	@include bp("extra-large") {
		$bottomImgWidth: 435px;
		bottom: -#{ calc($bottomImgWidth / 2) };
	}
}

.c-golden-triangle__content-img {
	display: block;
	overflow: hidden;
	border-radius: 50%;
	width: $bottomImgWidth;

	@include bp("extra-large") {
		width: 435px;
	}
}