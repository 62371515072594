﻿// CONTROL.FEATURED-CAROUSEL

.feature-carousel {

}

.c-feature-carousel-wrap {
    display: flex;
    flex-direction: column-reverse;
    position: relative;

    @include bp("medium") {
        flex-direction: row;
        height: 800px;
        position: static;
    }

    @include bp("extra-large") {
        height: 900px;
    }
}

.c-feature-carousel__content-wrap {
    width: 100%;

    @include bp("medium") {
        width: 50%;
    }
}


.c-feature-carousel__content {
    display: flex;
    height: 100%;
    flex-direction: column;
    z-index: 1;
    justify-content: center;
    padding: $lsu;

    @include bp("medium") {
        @include layout-split(left, 50%);
        position: relative;
    }
}

.c-feature-carousel__eyebrow-wrap {
    @include layout-wrapper;
    position: absolute;
    width: 100%;
    top: $lsu;
    text-align: left;

    @include bp("medium") {
        width: 200%;
        transform: translate(50%,0);
        right: 0;
        top: ($bsu * 3);
    }

    @include bp-below("medium") {
        z-index: 1;
        left: 0;
    }
}

.c-feature-carousel__eyebrow {
    @include font-heading-20;
    opacity: 0;
    visibility: hidden;
    height: 0;
    border-bottom: 1px solid $white;
    line-height: 2.5;

    &[aria-current="true"] {
        visibility: visible;
        opacity: 1;
        transition: all ease-in 0.3s;
        height: auto;
    }

    @include bp-below("medium") {
        text-shadow: 0 0 2px rgba($black, 0.4);
    }
}

.c-feature-carousel__heading-wrap {
    position: relative;

    @include bp("medium") {
        position: absolute;
        width: 200%;
        text-align: left;
        z-index: 1;
        top: ($hsu * 2);
        transform: translate(50%,0);
        @include layout-wrapper;
        max-width: 1000px;
        right: 0;
    }
}

.c-feature-carousel__heading {
    @include font-base-28b;
    opacity: 0;
    visibility: hidden;
    height: 0;
    transform: translateY(20%);

    @include bp("medium") {
        @include font-base-36b;
        text-shadow: 0 0 2px rgba($black, 0.4);
    }

    @include bp("large") {
        @include font-base-45b;
    }

    @include bp-below("medium") {
        position: absolute;
        margin-bottom: $bsu;
    }


    &[aria-current="true"] {
        visibility: visible;
        opacity: 1;
        transition: all ease-in 0.3s;
        height: auto;
        transform: translateY(0);

        @include bp-below("medium") {
            position: relative;
        }
    }
}

.c-feature-carousel__body-wrap {
    margin-top: auto;
    margin-bottom: auto;
    position: relative;

    @include bp("medium") {
        position: static;
    }
}

.c-feature-carousel__body {
    @include font-base-16;
    line-height: lh(26,16);
    opacity: 0;
    visibility: hidden;
    transition: all cubic-bezier(0.15, 0.72, 1, 1) 0.5s;
    transform: translateY(100%);
    position: absolute;

    @include bp("medium") {
        max-width: 400px;
        margin-top: 10%;
    }

    &[aria-current="true"] {
        visibility: visible;
        opacity: 1;
        transition: all cubic-bezier(0.15, 0.72, 1, 1) 0.5s;
        transform: translateY(0);

        @include bp-below("medium") {
            position: relative;
        }
    }
}

.c-feature-carousel__btn-wrap {
    display: flex;
    gap: $bsu;
    justify-content: flex-end;
    flex-direction: row;
    z-index: 1;
    margin-top: (($bsu * -3) - $tsu);
    margin-bottom: $lsu;

    @include bp("medium") {
        position: absolute;
        bottom: $lsu;
        right: $lsu;
        margin-top: 0;
        margin-bottom: 0;
    }
}

.c-feature-carousel__btn {
    @include circle(54px);
    font-size: 24px;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-b);
    color: var(--primary-a);
    border: 1px solid var(--primary-a);
    cursor: pointer;

    @include bp("medium") {
        background-color: transparent;
        color: var(--primary-b);
        border: 1px solid var(--primary-b);
    }

    &:hover,
    &:focus {
        background-color: var(--secondary-a);
        color: var(--secondary-b);
        border-color: var(--secondary-a);
    }
}

.c-feature-carousel__link-wrap {
    margin: $bsu 0 $lsu;

    @include bp("medium") {
        margin: 0;
        position: absolute;
        bottom: $lsu;
        right: -$hsu;
    }
}

.c-feature-carousel__link {
    @include button;
    opacity: 0;
    visibility: hidden;
    height: 0;
    position: absolute;
    background-color: var(--primary-b);
    color: var(--primary-a);
    border: 1px solid var(--primary-b);
    transform: translateY(20px);
    white-space: nowrap;

    @include bp("medium") {
        bottom: 0;
    }

    &:hover,
    &:focus {
        background-color: var(--secondary-a);
        color: var(--secondary-b);
        border-color: var(--secondary-a);
    }

    &[aria-current="true"] {
        visibility: visible;
        opacity: 1;
        transition: all ease-in 0.3s;
        height: auto;
        //position: relative;
        transform: translateY(0);
    }
}

.c-feature-carousel__image-wrap {
    width: 100%;
    overflow: hidden;

    @include bp("medium") {
        width: 50%;
    }
}

.c-feature-carousel__image {
    position: relative;
    z-index: 0;
    height: 100%;

}

.c-feature-carousel__slide {
    position: absolute;
    top: 0;
    transition: all cubic-bezier(0.7, 0.03, 0.34, 0.96) 1s;
    height: 100%;
    opacity:0;

    &[aria-current="true"] {
        position: relative;
        transition: all cubic-bezier(0.7, 0.03, 0.34, 0.96) 1s;
        opacity:1;
    }

    .o-fit {
        height: 100%;
    }
}

.c-feature-carousel__index {
    @include font-heading-20;
    display: none;

    @include bp("medium"){
        display: block;
        margin-bottom: $ssu;
    }
}