﻿.c-navigation__item {
	.t-bg__text {
		color: $white;
	}
}

.c-navigation__global-nav-list {
	.t-bg__text {
		color: $white;
	}
}

.c-navigation__social-item {
	.t-bg--rev--active {
		border-color: var(--primary-a);
		color: var(--primary-a);

		&:hover {
			border-color: var(--link--hover);
			color: var(--link--hover);
		}
	}
}
