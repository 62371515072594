.c-header-notification {
	background: #e5f75b !important;

	.c-header-notification__heading {
		font-size: 16px;
	}

	.c-header-notification__text {
		font-size: 14px;
	}
}
