﻿// CONTROL.PEOPLE

@import "control.people--main";
@import "control.people--aside";

///////////////
// Show more //
///////////////
.c-people__item {
	@include bp-below("small") {
		.c-people__list--show-more &:nth-child(n+2) {
			display: none;
			.c-people[aria-expanded="true"] & {
				display: block;
			}
		}
	}

	@include bp("small") {
		@include bp-below("medium") {
			.c-people__list--show-more &:nth-child(n+3) {
				display: none;
				.c-people[aria-expanded="true"] & {
					display: block;
				}
			}
		}
	}

	@include bp("medium") {
		@include bp-below("large") {
			.c-people__list--show-more &:nth-child(n+4) {
				display: none;
				.c-people[aria-expanded="true"] & {
					display: block;
				}
			}
		}
	}

	@include bp("large") {
		.c-people__list--show-more &:nth-child(n+5) {
			display: none;
			.c-people[aria-expanded="true"] & {
				display: block;
			}
		}

		@include bp-below("extra-large") {
			.c-people__list--show-more &:nth-child(n+3) {
				.l-page--has-sidebar .l-page__main-controls & {
					display: none;
				}
				.l-page--has-sidebar .l-page__main-controls .c-people[aria-expanded="true"] & {
					display: block;
				}
			}
		}
	}

	@include bp("extra-large") {
		.c-people__list--show-more &:nth-child(n+4) {
			.l-page--has-sidebar .l-page__main-controls & {
				display: none;
			}
			.l-page--has-sidebar .l-page__main-controls .c-people[aria-expanded="true"] & {
				display: block;
			}
		}
	}
}

.c-people__toggle {
	@include button;

	&::before {
		@include font-icon;
		content: $iconf-plus;

		display: inline-block;
		margin-right: $ssu;
	}

	.c-people__toggle--open {
		display: inline;
	}
	.c-people__toggle--close {
		display: none;
	}
	.c-people[aria-expanded="true"] & {
		&::before {
			content: $iconf-minus;
		}

		.c-people__toggle--open {
			display: none;
		}
		.c-people__toggle--close {
			display: inline;
		}
	}
}
