﻿.c-footer {
	.t-action--primary:hover {
		background: var(--link--hover);
		color: var(--primary-b);
	}

	.t-action--tertiary-outline:hover {
		background: var(--link--hover);
		color: var(--primary-b);
	}
}

.t-text--sec--active {
	color: $white;
}

.c-footer__top-container.t-bg--sec {
	background-color: var(--primary-a);

	.iconf-phone {
		color: var(--primary-b);
		font-size: 30px;

		&:before {
			margin-right: $ssu;
			vertical-align: middle;
		}
	}

	span {
		@include font-base-28b;
		vertical-align: middle;
	}
}

.c-footer__top-layout:after {
	background-color: var(--primary-a) !important;
}

.c-footer__social-item {
	a {
		&:hover {
			background: var(--link--hover);
		}

		&:before {
			margin-right: 0;
		}
	}
}

.c-footer__top-container::before {
	@include bp('large') {
		top: 3px;
	}
}
