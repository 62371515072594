﻿// CONTROL.TABBED-CONTENT

.c-tabbed-content__layout {
    @include layout-wrapper;
}

.c-tabbed-content__layout-wrap {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding-top: $lsu;
    padding-bottom: $lsu;

    @include bp("medium") {
        grid-template-columns: repeat(2, 1fr);
        padding-top: $hsu;
        padding-bottom: ($hsu * 1.5);
        align-items: center;
    }
}

.c-tabbed-content__heading {
    @include font-base-28b;

    @include bp("medium") {
        @include font-base-36b;
    }

    @include bp("large") {
        @include font-base-45b;
    }
}

.c-tabbed-content__eyebrow {
    @include font-heading-16b;
    display: inline-block;
    margin-bottom: $bsu;

    @include bp("large") {
        @include font-heading-18b;
    }
}

.c-tabbed-content__intro {
    @include font-heading-16b;
    line-height: lh(36, 20);

    @include bp("medium") {
        @include font-heading-18b;
    }

    @include bp("large") {
        @include font-heading-20b;
    }
}