﻿.c-share__icon.t-action--outline-sec {
	background: transparent;
	box-shadow: inset 0 0 0 1px var(--primary-a);
	color: var(--primary-a);
	border-radius: 100%;

	&:hover {
		box-shadow: inset 0 0 0 1px var(--link--hover);
		color: var(--link--hover);
		background: transparent;
	}
}
