$icomoon-font-family: "font-icons" !default;
$icomoon-font-path: "fonts" !default;

$iconf-safety: unquote('"\\e90d"');
$iconf-electric-panel: unquote('"\\e90e"');
$iconf-eco-house: unquote('"\\e90f"');
$iconf-Sustainability: unquote('"\\e910"');
$iconf-alter_connection: unquote('"\\e911"');
$iconf-connection: unquote('"\\e912"');
$iconf-Outage: unquote('"\\e913"');
$iconf-report_fault: unquote('"\\e914"');
$iconf-private-equity: unquote('"\\e906"');
$iconf-natural-resources: unquote('"\\e907"');
$iconf-infrastructure: unquote('"\\e908"');
$iconf-global-share: unquote('"\\e909"');
$iconf-real-estate: unquote('"\\e90a"');
$iconf-info: unquote('"\\2139"');
$iconf-bell: unquote('"\\1f514"');
$iconf-arrow-down-circle: unquote('"\\e901"');
$iconf-Airport: unquote('"\\e903"');
$iconf-Ports: unquote('"\\e904"');
$iconf-rails: unquote('"\\e905"');
$iconf-mobile: unquote('"\\1f4f1"');
$iconf-alert: unquote('"\\21"');
$iconf-arrow-down: unquote('"\\2193"');
$iconf-arrow-left: unquote('"\\2190"');
$iconf-arrow-right: unquote('"\\2192"');
$iconf-arrow-up: unquote('"\\2191"');
$iconf-home: unquote('"\\1f3e0"');
$iconf-search: unquote('"\\1f50d"');
$iconf-enlarge: unquote('"\\1f50d"');
$iconf-arrow-up-chevron: unquote('"\\2c4"');
$iconf-arrow-down-chevron: unquote('"\\2c5"');
$iconf-arrow-left-chevron: unquote('"\\2c2"');
$iconf-arrow-right-chevron: unquote('"\\2c3"');
$iconf-close: unquote('"\\274c"');
$iconf-cancel: unquote('"\\274c"');
$iconf-cross: unquote('"\\274c"');
$iconf-minus: unquote('"\\2d"');
$iconf-plus: unquote('"\\2b"');
$iconf-tick: unquote('"\\2713"');
$iconf-link: unquote('"\\1f517"');
$iconf-web: unquote('"\\1f517"');
$iconf-external: unquote('"\\e90c"');
$iconf-download: unquote('"\\e90b"');
$iconf-photo: unquote('"\\1f5bc"');
$iconf-camera: unquote('"\\1f4f7"');
$iconf-video: unquote('"\\1f4f9"');
$iconf-play: unquote('"\\25b6"');
$iconf-email: unquote('"\\2709"');
$iconf-print: unquote('"\\1f5b6"');
$iconf-phone: unquote('"\\1f4de"');
$iconf-fax: unquote('"\\1f5b7"');
$iconf-address: unquote('"\\1f4cd"');
$iconf-location: unquote('"\\1f4cd"');
$iconf-map: unquote('"\\1f5fa"');
$iconf-calendar: unquote('"\\1f4c5"');
$iconf-share: unquote('"\\e91c"');
$iconf-facebook: unquote('"\\e922"');
$iconf-twitter: unquote('"\\e91f"');
$iconf-youtube: unquote('"\\e924"');
$iconf-flickr: unquote('"\\e920"');
$iconf-pinterest: unquote('"\\e921"');
$iconf-instagram: unquote('"\\e902"');
$iconf-linkedin: unquote('"\\e923"');
$iconf-weibo: unquote('"\\e928"');
$iconf-wechat: unquote('"\\e900"');
$iconf-youku: unquote('"\\e927"');
$iconf-snapchat: unquote('"\\e926"');
$iconf-blogger: unquote('"\\e925"');
$iconf-tumblr: unquote('"\\e91e"');
$iconf-tripadvisor: unquote('"\\e91d"');
$iconf-click: unquote('"\\e91a"');
$iconf-chat: unquote('"\\1f4ac"');
$iconf-filter: unquote('"\\e929"');
$iconf-reload: unquote('"\\1f5d8"');

