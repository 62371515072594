﻿// CONTROL.OUR-STORY

.c-our-story {
    background-color: $carcoal;
	color: $white;
}
.c-our-story__heading-mi {
	color: var(--primary-a);
}

.c-our-story__heading {
	color: $white;
}

.c-our-story__link.t-ignore:hover {
	color: var(--link--hover);
}
