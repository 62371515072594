// COMPONENT.RELATED-ITEMS

.c-related-items {
	@include control;
}

.c-related-items__layout {
	@include layout-wrapper;
}

.c-related-items__intro {
	margin-bottom: $bsu;

	p {
		@include font-body-text;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.c-related-item {
	margin-bottom: $tsu;
}

.c-related-item__link {
	@include link-transition;
	position: relative;
	display: block;
	padding: $bsu;
	padding-left: $bsu * 3;
	border: 1px solid $base-ui-color;
	border-radius: 4px;
	background-color: $white;

	&:focus:not(:focus-visible) {
		outline: none;
	}

	&:hover,
	&:focus {
		border-color: $brand;
	}
}

.c-related-item__text {
	@include font-heading-16b;
	color: $brand;
}

.c-related-item__icon {
	@include abs-center-v;
	left: $bsu;
	font-size: 22px;
	color: $brand;
}

.c-related-item__meta {
	@include font-base-10b;
	display: block;
	margin-top: $tsu;
	color: $grey--dark;
}
