// COMPONENT.PERSON

$person-bp--tablet: 650px;
$person-bp: $layout-page-bp;

$person-header-height: 220px;

.c-person {
	position: relative;
}

////////////
// HEADER //
////////////
.c-person__header {
	@include bp($person-bp--tablet) {
		height: $person-header-height;
	}
}

.c-person__header-layout {
	@include layout-wrapper;
	padding-top: $bsu;
	padding-bottom: $hsu;

	@include bp($person-bp--tablet) {
		height: 100%;
		@include bp-below($person-bp) {
			margin-right: 50vw;
		}
	}

	@include bp($person-bp) {
		padding-bottom: 0;
	}

	position: relative;
	isolation: isolate;
	&::after {
		content: "";
		position: absolute;

		@include bp-below($person-bp) {
			right: 0;
			bottom: 0;

			width: 200px;
			height: 100px;
		}
		@include bp($person-bp) {
			right: $layout-aside-width - $bsu;
			bottom: -61px;

			width: 356px;
			height: 180px;
		}

		background: url("/content/images/interface/bg/triangle.svg") no-repeat bottom right;
		background-size: contain;
	}
}

.c-person__header-layout-inner {
	// Move above the layout's background image
	position: relative;
	z-index: 1;

	@include bp($person-bp) {
		padding-right: $layout-aside-width + $lsu;
	}
}

.c-person__header-heading {
	display: block;

	@include bp-below($person-bp) {
		margin-bottom: $ssu;
		@include font-heading-28b;
	}
	@include bp($person-bp) {
		margin-bottom: $bsu;
		@include font-heading-45b;
	}
}

.c-person__header-role {
	display: block;

	@include bp-below($person-bp) {
		margin-bottom: $ssu;
		@include font-heading-16bu;
	}
	@include bp($person-bp) {
		margin-bottom: $bsu;
		@include font-heading-20bu;
	}
}

.c-person__header-location {
	display: block;

	@include bp-below($person-bp) {
		@include font-heading-12b;
	}
	@include bp($person-bp) {
		@include font-heading-16b;
	}
}

/////////////
// CONTACT //
/////////////
.c-person__contact {
	@include layout-wrapper;
}

.c-person__contact-heading {
	display: block;
	margin-bottom: $bsu;

	@include font-heading-20b;
}

.c-person__contact-list {
	@include list-reset;
	display: flex;
	flex-wrap: wrap;

	margin-top: -$ssu;
	margin-left: -$hsu;

	max-width: 900px;
}

.c-person__contact-item {
	padding-top: $ssu;
	padding-left: $hsu;

	width: 100%;
	@include bp($person-bp) {
		width: 50%;
	}
}

.c-person__contact-link {
	display: inline-flex;
	max-width: 100%;
	align-items: center;

	@include link-fancy(after, #{32px + $bsu});
}

.c-person__contact-link-icon {
	@include circle(32px);
	flex-shrink: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: $bsu;
}

.c-person__contact-link-text {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	max-width: calc(100% - 32px);

	padding: $ssu 0;
}

/////////////
// SIDEBAR //
/////////////
.c-person__sidebar {
	@include bp($layout-page-bp) {
		margin-top: -($person-header-height + $lsu);
	}
}

.c-person__sidebar-image {
	@include figure;

	@include bp-below($layout-page-bp) {
		display: none;
	}
}

//////////
// MAIN //
//////////
.c-person__main-layout {
	@include layout-wrapper;

	@include flex-extend;
	@include bp-below($person-bp) {
		flex-direction: column;
	}
	@include bp($person-bp--tablet) {
		flex-direction: row-reverse;

		margin-left: -$lsu;
		.c-person__main-image,
		.c-person__contact {
			width: 50%;
			flex-shrink: 0;

			padding-left: $lsu;
		}
	}

	margin-bottom: $bsu;
	@include bp($layout-page-bp) {
		margin-bottom: $lsu;
	}
}

.c-person__main-image {
	@include figure;

	margin-top: -$lsu;
	@include bp-below($person-bp--tablet) {
		margin-bottom: $lsu;
		margin-bottom: var(--control-gap);
	}
	@include bp($person-bp--tablet) {
		margin-top: -240px;
	}
	@include bp($person-bp) {
		display: none;
	}
}

.c-person__introduction {
	@include layout-wrapper;

	@include font-base-20-tall;

	margin-top: $bsu;
	margin-bottom: $bsu;
	@include bp($layout-page-bp) {
		margin-top: $lsu;
		margin-bottom: $lsu;
	}

	> *:last-child {
		margin-bottom: 0;
	}
}

.c-person__main-controls {
	// Only here for :first-child and :last-child purposes
}
