﻿// CONTROL.IMAGE-SERIRES

.c-image-serires {
	@include control;

	.flickity-prev-next-button {
		display: none;
	}
}

.c-image-serires__top-layout {
	@include layout-wrapper;
	margin-bottom: $lsu;

	@include bp("large") {
		margin-bottom: $hsu;
	}
}

.c-image-serires__heading {
	@include font-heading-36b;
	margin-top: $ssu;

	@include bp("extra-large") {
		@include font-heading-90b;
		line-height: 1;
	}
}

.c-image-serires__heading-mi {
	@include font-heading-18b;
}

.c-image-serires__body {
	@include font-base-16b;

	@include bp("extra-large") {
		@include font-base-20b;
	}
}

.c-image-serires__slides-nav-wrapper {
	@include layout-wrapper;
	position: relative;
	margin-top: $bsu;
	margin-bottom: $bsu;
}

.c-image-serires__buttons {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.c-image-serires__button {
	@include button("small", true);
	position: absolute;
	z-index: 1;

	@include bp("medium") {
		@include button("medium", true);
	}

	&.c-image-serires__button-prev {
		left: $bsu;
	}

	&.c-image-serires__button-next {
		right: $bsu;
	}
}

.c-image-serires__slides-nav {
	padding: 0 ($lsu + $ssu + $tsu);
}

.c-image-serires__slides-nav-item {
	@include font-base-18b;
	padding: 0 $ssu;
	margin: 0;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;

	@include bp("medium") {
		width: auto;
		margin: ($ssu * 1.5) $bsu;
	}

	@include bp("large") {
		width: auto;
	}

	@include bp("extra-large") {
		@include font-base-24b;
	}

	&.is-nav-selected {
		color: var(--primary-a);
	}
}

.c-image-serires__slides {
	@include list-reset;
}

.c-image-serires__slide {
	width: 100%;

	&:nth-child(n+2) {
		display: none;

		.flickity-enabled & {
			display: block;
		}
	}
}

.c-image-serires__slide-item {
	position:relative;	
}

.c-image-serires__slide-item {
	@media (min-width: 1024px) and (max-width: 1280px) and (max-height: 650px) {
		width: 80%;
		margin: 0 auto;
	}
}

.c-image-serires__bottom-layout {
	@include layout-wrapper;
}

.c-image-serires__caption-wrapper {
	bottom: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

.c-image-serires__caption {
	@include layout-wrapper;
	@include flex-extend;
	align-items: flex-start;
	justify-content: flex-start;
	opacity: 0;
	z-index: 1;
	border: none;
	margin: $bsu 0;
	flex-direction: column;

	.c-image-serires__slide.is-selected & {
		opacity: 1;
	}
}

.c-image-serires__caption-title {
	flex: 1 0;
	margin: $bsu 0;
	@include font-heading-20b--tall;


	@include bp("large") {
		@include font-heading-28b--tall;
	}
}

.c-image-serires__caption-text {
	flex: 1;
	@include font-body-text;
}