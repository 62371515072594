﻿.c-cta__body.t-bg--sec.t-bg__text--sec.t-before-bg.t-before-bg {
	background-color: $carcoal;

	&:before {
		background-color: var(--link--hover);
	}

	.c-cta__body-wrapper {
		color: $white;
	}

	.c-cta__link.t-action--primary:hover {
		background-color: var(--link--hover);
		color: $black;
	}
}
