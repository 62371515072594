@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?lwti7z') format('woff2'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?lwti7z') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?lwti7z') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?lwti7z##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="iconf-"], [class*=" iconf-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.iconf-safety {
  &:before {
    content: $iconf-safety; 
  }
}
.iconf-electric-panel {
  &:before {
    content: $iconf-electric-panel; 
  }
}
.iconf-eco-house {
  &:before {
    content: $iconf-eco-house; 
  }
}
.iconf-Sustainability {
  &:before {
    content: $iconf-Sustainability; 
  }
}
.iconf-alter_connection {
  &:before {
    content: $iconf-alter_connection; 
  }
}
.iconf-connection {
  &:before {
    content: $iconf-connection; 
  }
}
.iconf-Outage {
  &:before {
    content: $iconf-Outage; 
  }
}
.iconf-report_fault {
  &:before {
    content: $iconf-report_fault; 
  }
}
.iconf-private-equity {
  &:before {
    content: $iconf-private-equity; 
  }
}
.iconf-natural-resources {
  &:before {
    content: $iconf-natural-resources; 
  }
}
.iconf-infrastructure {
  &:before {
    content: $iconf-infrastructure; 
  }
}
.iconf-global-share {
  &:before {
    content: $iconf-global-share; 
  }
}
.iconf-real-estate {
  &:before {
    content: $iconf-real-estate; 
  }
}
.iconf-info {
  &:before {
    content: $iconf-info; 
  }
}
.iconf-bell {
  &:before {
    content: $iconf-bell; 
  }
}
.iconf-arrow-down-circle {
  &:before {
    content: $iconf-arrow-down-circle; 
  }
}
.iconf-Airport {
  &:before {
    content: $iconf-Airport; 
  }
}
.iconf-Ports {
  &:before {
    content: $iconf-Ports; 
  }
}
.iconf-rails {
  &:before {
    content: $iconf-rails; 
  }
}
.iconf-mobile {
  &:before {
    content: $iconf-mobile; 
  }
}
.iconf-alert {
  &:before {
    content: $iconf-alert; 
  }
}
.iconf-arrow-down {
  &:before {
    content: $iconf-arrow-down; 
  }
}
.iconf-arrow-left {
  &:before {
    content: $iconf-arrow-left; 
  }
}
.iconf-arrow-right {
  &:before {
    content: $iconf-arrow-right; 
  }
}
.iconf-arrow-up {
  &:before {
    content: $iconf-arrow-up; 
  }
}
.iconf-home {
  &:before {
    content: $iconf-home; 
  }
}
.iconf-search {
  &:before {
    content: $iconf-search; 
  }
}
.iconf-enlarge {
  &:before {
    content: $iconf-enlarge; 
  }
}
.iconf-arrow-up-chevron {
  &:before {
    content: $iconf-arrow-up-chevron; 
  }
}
.iconf-arrow-down-chevron {
  &:before {
    content: $iconf-arrow-down-chevron; 
  }
}
.iconf-arrow-left-chevron {
  &:before {
    content: $iconf-arrow-left-chevron; 
  }
}
.iconf-arrow-right-chevron {
  &:before {
    content: $iconf-arrow-right-chevron; 
  }
}
.iconf-close {
  &:before {
    content: $iconf-close; 
  }
}
.iconf-cancel {
  &:before {
    content: $iconf-cancel; 
  }
}
.iconf-cross {
  &:before {
    content: $iconf-cross; 
  }
}
.iconf-minus {
  &:before {
    content: $iconf-minus; 
  }
}
.iconf-plus {
  &:before {
    content: $iconf-plus; 
  }
}
.iconf-tick {
  &:before {
    content: $iconf-tick; 
  }
}
.iconf-link {
  &:before {
    content: $iconf-link; 
  }
}
.iconf-web {
  &:before {
    content: $iconf-web; 
  }
}
.iconf-external {
  &:before {
    content: $iconf-external; 
  }
}
.iconf-download {
  &:before {
    content: $iconf-download; 
  }
}
.iconf-photo {
  &:before {
    content: $iconf-photo; 
  }
}
.iconf-camera {
  &:before {
    content: $iconf-camera; 
  }
}
.iconf-video {
  &:before {
    content: $iconf-video; 
  }
}
.iconf-play {
  &:before {
    content: $iconf-play; 
  }
}
.iconf-email {
  &:before {
    content: $iconf-email; 
  }
}
.iconf-print {
  &:before {
    content: $iconf-print; 
  }
}
.iconf-phone {
  &:before {
    content: $iconf-phone; 
  }
}
.iconf-fax {
  &:before {
    content: $iconf-fax; 
  }
}
.iconf-address {
  &:before {
    content: $iconf-address; 
  }
}
.iconf-location {
  &:before {
    content: $iconf-location; 
  }
}
.iconf-map {
  &:before {
    content: $iconf-map; 
  }
}
.iconf-calendar {
  &:before {
    content: $iconf-calendar; 
  }
}
.iconf-share {
  &:before {
    content: $iconf-share; 
  }
}
.iconf-facebook {
  &:before {
    content: $iconf-facebook; 
  }
}
.iconf-twitter {
  &:before {
    content: $iconf-twitter; 
  }
}
.iconf-youtube {
  &:before {
    content: $iconf-youtube; 
  }
}
.iconf-flickr {
  &:before {
    content: $iconf-flickr; 
  }
}
.iconf-pinterest {
  &:before {
    content: $iconf-pinterest; 
  }
}
.iconf-instagram {
  &:before {
    content: $iconf-instagram; 
  }
}
.iconf-linkedin {
  &:before {
    content: $iconf-linkedin; 
  }
}
.iconf-weibo {
  &:before {
    content: $iconf-weibo; 
  }
}
.iconf-wechat {
  &:before {
    content: $iconf-wechat; 
  }
}
.iconf-youku {
  &:before {
    content: $iconf-youku; 
  }
}
.iconf-snapchat {
  &:before {
    content: $iconf-snapchat; 
  }
}
.iconf-blogger {
  &:before {
    content: $iconf-blogger; 
  }
}
.iconf-tumblr {
  &:before {
    content: $iconf-tumblr; 
  }
}
.iconf-tripadvisor {
  &:before {
    content: $iconf-tripadvisor; 
  }
}
.iconf-click {
  &:before {
    content: $iconf-click; 
  }
}
.iconf-chat {
  &:before {
    content: $iconf-chat; 
  }
}
.iconf-filter {
  &:before {
    content: $iconf-filter; 
  }
}
.iconf-reload {
  &:before {
    content: $iconf-reload; 
  }
}

