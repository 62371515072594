$info-panel-bp: "large";

@mixin info-panel__section {
	@include bp-below($info-panel-bp) {
		// Force equal width
		flex: 1 0 auto;
		width: 0;
		min-width: 300px;

		&:not(:last-child) {
			padding-right: $bsu;
			border-right: 1px solid $grey--light;
		}
		&:not(:first-child) {
			padding-left: $bsu;
		}
	}
	@include bp($info-panel-bp) {
		width: 200px;
	}
	@include bp("extra-large") {
		width: 245px;
	}
}

@mixin info-panel__icon($icon: "", $size: 32px) {
	@include circle($size);
	font-size: $size;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-bottom: $ssu;

	background: $body-color;
	color: $white;
	background: var(--primary-a);
	color: var(--primary-b);

	&::before {
		font-size: 0.5em;
		@if ($icon != "") {
			@include font-icon;
			content: $icon;
		}
	}
}

.c-info-panel {
	background: $white;
	box-shadow: 0 0 5px rgba($black, 0.1);
	color: $body-color;
	padding: $bsu;

	display: flex;
	@include bp-below($info-panel-bp) {
		width: 100%;
		overflow-x: auto;
	}
	@include bp($info-panel-bp) {
		flex-wrap: wrap;
		max-width: unquote("min(50%, #{$bsu * 2 + 400px})");

		.c-hero & {
			position: absolute;
			z-index: 1;
			right: 0;

			transform: translateY(-100%);
			margin-top: $hsu;
		}
	}
	@include bp("extra-large") {
		max-width: $bsu * 2 + 490px;
	}
}

/////////////
// Message //
/////////////
.c-info-panel__message {
	@include info-panel__section;
	@include bp($info-panel-bp) {
		width: 400px;

		&:not(:last-child) {
			padding-bottom: $bsu;
			border-bottom: 1px solid $grey--light;
			margin-bottom: $bsu;
		}
	}
	@include bp("extra-large") {
		width: 490px;
	}
}

.c-info-panel__message-icon {
	@include info-panel__icon($iconf-bell);

	@include bp($info-panel-bp) {
		margin-top: calc(-#{$bsu} - 0.5em);
	}
}

.c-info-panel__message-heading {
	display: block;
	margin-bottom: $ssu;

	@include font-heading-12b;
}

.c-info-panel__message-content {
	@include bp($info-panel-bp) {
		@supports (border-left-color: var(--primary-a)) {
			padding-left: $ssu;
			border-left: 4px solid var(--primary-a);
		}
	}
}

.c-info-panel__message-date {
	display: block;
	margin-bottom: $ssu;

	@include font-base-12;
}

.c-info-panel__message-text {
	@include font-body-text;

	> p:last-child {
		margin-bottom: 0;
	}
}

/////////////////
// Information //
/////////////////
.c-info-panel__information {
	@include info-panel__section;
	@include bp($info-panel-bp) {
		&:not(:last-child) {
			padding-right: $bsu;
			border-right: 1px solid $grey--light;
		}
	}
}

.c-info-panel__information-icon {
	@include info-panel__icon($iconf-info);
}

.c-info-panel__information-list {
	@include list-reset;
}

.c-info-panel__information-list-item {
	display: block;
	margin-bottom: $ssu;
	&:last-child {
		margin-bottom: 0;
	}
}

.c-info-panel__information-list-item-name {
	@include font-heading-12b;
}

.c-info-panel__information-list-item-value {
	@include font-base-18;
}

///////////
// Links //
///////////
.c-info-panel__links {
	@include info-panel__section;
	@include bp($info-panel-bp) {
		&:not(:first-child) {
			padding-left: $bsu;
		}
	}
}

.c-info-panel__links-icon {
	@include info-panel__icon($iconf-link);
}

.c-info-panel__links-list {
	@include list-reset;
}

.c-info-panel__links-item {
	display: block;
	margin-bottom: $ssu;

	&:last-child {
		margin-bottom: 0;
	}
}

.c-info-panel__links-link {
	display: block;
	@include font-base-14b;

	&::after {
		@include font-icon;
		content: $iconf-arrow-right;

		display: inline-block;
		margin-left: $tsu;
		vertical-align: center;
	}
	&[href^="http:"],
	&[href^="https:"] {
		&::after {
			content: $iconf-external;
		}
	}
}
