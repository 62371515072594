﻿// CONTROL.IMAGE-GALLERY

.c-image-gallery {
	@include control;

	.flickity-prev-next-button {
		top: calc(50% - 29px);
	}
}

.c-image-gallery__layout {
	@include layout-wrapper;
}

.c-image-gallery__slides {
	@include list-reset;
}

.c-image-gallery__slide {
	width: 100%;

	&:nth-child(n+2) {
		display: none;

		.flickity-enabled & {
			display: block;
		}
	}
}

.c-image-gallery__caption {
	@include flex-extend;
	align-items: flex-start;
	justify-content: flex-end;
	opacity: 0;

	.c-image-gallery__slide.is-selected & {
		opacity: 1;
	}
}

.c-image-gallery__caption-text {
	flex: 1 1 auto;

	@include font-body-text;
}

.c-image-gallery__caption-count {
	display: none;

	@include bp("medium") {
		@include font-base-14b;
		display: inline;
		padding-left: $lsu;
		white-space: nowrap;
	}
}
