﻿// CONTROL.IMAGE

.c-image {
	@include control;
}

.c-image__layout {
	@include layout-wrapper;
}

.c-image__heading {
	@include control__heading;
}

.c-image__figure {
	@include figure;
}

.c-image__figcaption {
	@include control__figcaption;
}
