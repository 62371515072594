﻿.c-statistics-panel {
	&.t-bg--sec {
		background-color: $carcoal;
		color: $white;
	}

	.c-statistics-panel__heading {
		color: $white;
	}

	.t-bg__text--sec {
		color: $white;
	}
}
