// OBJECT.LIST

.o-list--reset {
	@include list-reset;
}

.o-list--inline {
	@include list-inline;
}

.o-list--stacked {
	@include list-stacked;
}
