﻿.c-home-intro {
	@include layout-wrapper;
	text-align: center;
	padding: 30px;
}

.c-home-intro__strapline {
	@include font-base-12b;
	margin-bottom: $bsu;

	&:after {
		content: "";
		display: block;
		width: 100px;
		height: 2px;
		margin: 20px auto;
		background: $energy--lime;
	}

	@include bp('large') {
		@include font-heading-18b;
	}
}

.c-home-intro__text {
	line-height: 26px;
	color: $black;

	strong {
		@include font-heading-24b;
	}

	@include bp('large') {
		strong {
			@include font-heading-45b;
		}
	}
}
