﻿
.c-hero--homepage {
	@include bp('large') {
		.c-hero__body-content-top {
			margin-top: 0;
			width: 66%;
			padding: 0;
			min-height: 0;
			height: 66%;
			justify-content: flex-end;
		}

		.c-hero__primary {
			height: 100vh;
			max-height: 760px;

			&:before {
				content: '';
				display: block;
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				background: rgb(0,0,0);
				z-index: 1;
				background: linear-gradient(147deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 46%);
			}
		}
	}

	.c-hero__title {
		@include font-heading-28b;
		text-shadow: none;
		color: $white;

		@include bp('large') {
			@include font-heading-60b;
		}
	}
}


/* HERO PANELS - ENERGY */

.c-hero__panels {
	@include bp('large') {
		@include layout-wrapper;
		margin: auto;
		position: absolute;
		bottom: 0;
		display: flex;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		z-index: 1;
	}
}

.c-hero__panel {
	padding: 40px 30px;
	transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

	@include bp('large') {
		margin-top: 40px;

		&:hover {
			padding-bottom: 60px;
			margin-top: 20px;

			.c-hero__panel-arrow {
				opacity: 1;
			}
		}
	}

	&:nth-of-type(1) {
		background: $energy--light-lime;

		@include bp('large') {
			border-radius: 12px 0 0 0;
		}
	}

	&:nth-of-type(2) {
		background: $energy--lime;
	}

	&:nth-of-type(3) {
		background: $energy--dark-lime;

		@include bp('large') {
			border-radius: 0 12px 0 0;
		}
	}
}

.c-hero__panel-link {
	color: $body-color;
	display: flex;
}

.c-hero__panel-icon {
	border: 1px $black solid;
	@include circle(60px);
	display: block;
	background: $white;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 30px;
	flex: 0 0 60px;
}

.c-hero__panel-body {
	padding-left: $bsu;
	position: relative;
}

.c-hero__panel-name {
	@include font-base-20b;
	margin-bottom: $ssu;
	display: block;
}

.c-hero__panel-text {
	margin: 0;
}

.c-hero__panel-arrow {
	float: right;
	position: absolute;
	right: 0;
	bottom: -20px;
	font-size: 25px;
	color: $black;
	opacity: 0;
	transition: opacity 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
