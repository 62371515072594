﻿// CONTROL.IMAGE-FEATURE

@mixin image-feature--wide {
	.c-image-feature__layout {
		@include flex-extend;
		padding: 0;
	}

	.c-image-feature__layout--right {
		flex-flow: row-reverse;
	}

	.c-image-feature__figure {
		flex: 0 0 50%;
		align-self: stretch;
		margin-bottom: 0;
	}

	.c-image-feature__image {
		height: 100%;
	}

	.c-image-feature__body {
		align-self: center;
		padding: ($bsu * 3) ($bsu * 2);
	}
}

.l-page:not(.l-page--has-sidebar) {
	@include bp("medium") {
		@include image-feature--wide;
	}
}
.l-page--has-sidebar {
	@include bp("medium") {
		@include bp-below("large") {
			@include image-feature--wide;
		}
	}
	@include bp("extra-large") {
		@include image-feature--wide;
	}
}

.c-image-feature__layout {
	background-color: $grey--lightest;

	$sectionTheme: ('default','commercial', 'innovation-hub', 'retail', 'residential', 'green-spaces', 'inland-port', 'logistics', 'tgh');
	$sectionThemeColors: ($default, $commercial, $innovation-hub, $retail, $residential, $green-spaces, $inland-port, $logistics, $tgh);
	$base-color: zip($sectionTheme, $sectionThemeColors);

	@each $c in $base-color {

		@if( nth($c,1) != 'default') {
			&.section-theme-#{nth($c,1)} {
				background-color: nth($c,2) !important;
			}
		}
	}
}

.c-image-feature__layout--right {}

.c-image-feature__figure {}

.c-image-feature__image {}

.c-image-feature__body {
	padding: $bsu;
	text-align: center;
	width: 100%;

	@include bp("large") {
		padding: $bsu * 4;

		.l-page--has-sidebar & {
			padding: ($bsu * 3) ($bsu * 2);
		}
	}

	> *:last-child,
	&:last-child {
		margin-bottom: 0;
	}
}

.c-image-feature__heading {
	@include font-base-20b;
	position: relative;
	margin-bottom: 0.5em;
	color: $brand;

	@include bp("large") {
		@include font-base-28b;
		padding-bottom: 0.95em;
		margin-bottom: 1.35em;

		&::before {
			@include pseudo-element;
			right: 50%;
			bottom: 0;
			width: 100px;
			height: 5px;
			background-color: $brand;
			transform: translateX(50%)
		}
	}
}

.c-image-feature__text {
	margin-bottom: $bsu;

	@include bp("large") {
		margin-bottom: $bsu * 1.5;
	}
}

.c-image-feature__link {
	@include button("medium");

	@include bp("large") {
		@include button-size("large");
	}
}
