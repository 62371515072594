﻿// CONTROL.FILTER

$filter-bp: "medium";

.c-filter {
	@include layout-wrapper;

	padding-bottom: $bsu;
	@include bp("large") {
		padding-bottom: $lsu;
	}
}

.c-filter__form {}

.c-filter__heading {
	display: block;
	margin-bottom: $ssu;

	@include font-heading-16b;
}

.c-filter__body {
	@include bp($filter-bp) {
		display: flex;
		margin-left: -$bsu;
	}
}

.c-filter__list {
	@include list-reset;

	@include bp($filter-bp) {
		@include bp-below("large") {
			flex-grow: 1;
		}
		padding-left: $bsu;
	}

	display: flex;
	flex-wrap: wrap;

	margin-top: -$bsu;
	margin-left: -$bsu;
}

.c-filter__item {
	width: 100%;
	@include bp($filter-bp) {
		width: 50%;
	}
	@include bp("extra-large") {
		width: auto;
		flex-grow: 1;
		min-width: 250px;
		max-width: 350px;
	}

	padding-top: $bsu;
	padding-left: $bsu;
}

.c-filter__input {
	@include form-input;
}

.c-filter__controls {
	display: flex;
	align-items: flex-end;

	@include bp-below($filter-bp) {
		padding-top: $bsu;
	}
	@include bp($filter-bp) {
		padding-left: $bsu;
	}
}

.c-filter__submit {
	@include button;
	white-space: nowrap;
}

/////////////
// SUMMARY //
/////////////
.c-filter__summary {
	@include list-reset;
	display: flex;
}

.c-filter__summary-title {
	@include font-body-text;
}

.c-filter__summary-item {
	margin-left: $tsu;

	@include font-body-text;
}

.c-filter__summary-link {
	display: inline-block;
	margin-right: $tsu;

	&::after {
		@include font-icon;
		content: $iconf-cross;

		display: inline-block;
		vertical-align: super;

		font-size: 0.7em;
	}
}

///////////
// MODAL //
///////////
.c-filter__modal-focus {
	// Has tabindex="-1" only for receiving focus when modal opens
	&:focus {
		outline: none;
	}
}

.c-filter__submit {
	// Ensure its visibility changes immediately based on its modal ancestor
	transition: visibility 0s !important;
}

@include bp($filter-bp) {
	.c-filter__modal-trigger-area {
		display: none;
	}

	.c-filter__modal-wrapper {
		display: contents;
	}

	.c-filter__modal-close {
		display: none;
	}

	.c-filter__modal-dummy {
		display: none;
	}
}

@include bp-below($filter-bp) {
	.c-filter__modal-trigger-area {
		display: flex;
		justify-content: center;
	}

	.c-filter__modal-trigger-button {
		@include button;
		width: 100%;
		text-align: center;

		&::before {
			@include font-icon;
			content: $iconf-filter;

			display: inline-block;
			vertical-align: middle;
			margin-right: $tsu;

			// Further adjust alignment so it looks perfect
			position: relative;
			top: -2px;
		}
	}

	.c-filter__modal-close {
		position: absolute;
		// Offset by 1px so focus style isn't cut off
		top: 1px;
		right: 1px;

		@include button-reset;

		@include flex-extend;
		padding: 2px;
		font-size: 40px;
		line-height: 1;

		&::before {
			@include font-icon;
			content: $iconf-close;
		}
	}

	.c-filter__modal {
		visibility: hidden;
		&[aria-hidden="false"] {
			visibility: visible;
		}

		&.allow-animations {
			animation: 0.5s slideoutDown forwards,
				0.5s becomeHidden forwards;
			&[aria-hidden="false"] {
				animation: 0.5s slideinUp forwards,
					0.5s becomeVisible forwards;
			}
		}

		display: flex;
		align-items: center;
		justify-content: center;

		position: fixed;
		z-index: 10;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		.c-filter__form {
			position: relative;
			overflow: auto;
			width: 100%;
			height: 100%;
			padding: 44px;
			background: $white;
		}
	}
}
