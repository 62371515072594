// CONTROL.IMAGE-GRID

@use "sass:math";

.c-image-grid {
	@include control;
}

.c-image-grid__layout {
	@include layout-wrapper;
}

.c-image-grid__heading {
	@include control__heading;
}


.c-image-grid__list {
	@include list-reset;
	display: flex;
	flex-wrap: wrap;
	margin-left: -$bsu;
	margin-top: -$bsu;

	@supports (display: grid) {
		display: grid;

		.c-image-grid--large & {
			@include grid-ram(400px);
			grid-template-columns: repeat(1, 1fr);

			@include bp("medium") {
				grid-template-columns: repeat(2, 1fr);
			}

			.c-image-grid__item-image {
				padding-bottom: 56.25%;
			}
		}
		// Medium is the default
		&, .c-image-grid--medium & {
			@include grid-ram(300px);
			grid-template-columns: repeat(2, 1fr);

			@include bp("medium") {
				grid-template-columns: repeat(4, 1fr);
			}

			.c-image-grid__item-image {
				padding-bottom: 75%;
			}
		}

		.c-image-grid--small & {
			@include grid-ram(230px);
			grid-template-columns: repeat(3, 1fr);

			@include bp("medium") {
				grid-template-columns: repeat(6, 1fr);
			}

			.c-image-grid__item-image {
				padding-bottom: 100%;
			}
		}

		.c-image-grid--extra-small & {
			@include grid-ram(230px);
			grid-template-columns: repeat(3, 1fr);

			@include bp("medium") {
				grid-template-columns: repeat(6, 1fr);
			}

			@include bp("large") {
				grid-template-columns: repeat(8, 1fr);

				.l-page--has-sidebar & {
					grid-template-columns: repeat(6, 1fr);
				}
			}

			.c-image-grid__item-image {
				padding-bottom: 100%;
			}
		}

		margin: 0;
		grid-gap: $bsu;
	}
}

.c-image-grid__item {
	display: flex;
	flex-direction: column;

	padding-left: $bsu;
	padding-top: $bsu;

	
	.c-image-grid--large & {
		width: percentage(calc(1 / 1));
		@include bp("medium") {
			width: percentage(calc(1 / 2));
		}
		@include bp("large") {
			width: percentage(calc(1 / 3));
		}
	}
	// Medium is the default
	&, .c-image-grid--medium & {
		width: percentage(calc(1 / 2));
		@include bp("medium") {
			width: percentage(calc(1 / 3));
		}
		@include bp("large") {
			width: percentage(calc(1 / 4));
		}
	}
	.c-image-grid--small & {
		width: percentage(calc(1 / 3));
		@include bp("medium") {
			width: percentage(calc(1 / 4));
		}
		@include bp("large") {
			width: percentage(calc(1 / 5));
		}
	}


	@supports (display: grid) {
		padding: 0;
		&,
		.c-image-grid--large &,
		.c-image-grid--medium &,
		.c-image-grid--small & {
			width: 100%;
		}
	}

	position: relative;
}

.c-image-grid__item-wrap {
	display: flex;
	flex-direction: column;
	text-align: center;

	&:not(.c-image-grid__img-cropped) {
		//height: 100%;
	}
}

.c-image-grid__item-link {
	display: flex;
	flex-direction: column;
	width: 100%;
	text-align: center;
	flex: 1;

	&:hover,
	&:focus-visible {
		.c-image-grid__item-image {
			border-color: var(--primary-a);
		}
	}
}

.c-image-grid__item-figure {
	flex-grow: 1;
	@include figure;
	margin-bottom: 0;
	text-align: center;

	.c-image-grid__no-img-cropped & {
		.c-image-grid__item-image {
			background-color: transparent;
		}

		.o-fit__image {
			background-color: transparent;
		}
	}
}

.c-image-grid__has-link,
a.c-image-grid__item-figure {


	&:hover,
	&:focus-visible {


		.c-image-grid__item-image {
			border-color: var(--primary-a);

			.c-image-grid--cover & {
				transform: scale(1);
			}

			.c-image-grid--contain & {
				// Scale up less because these may
				// be logos that shouldn't get cut off
				transform: scale(1);
			}
		}

		.c-image-grid__item-text-link {
			color: var(--primary-a);
		}

		.c-image-grid__item-title {
			color: var(--primary-a);
		}
	}
}

.c-image-grid__item-image {
	width: 100%;
	height: 100%;
	overflow: hidden;
	
	border: 1px solid $grey--light;

	.c-image-grid--cover & {
		&, .o-fit__image {
			object-fit: cover;
		}
	}

	.c-image-grid--contain & {
		&, .o-fit__image {
			object-fit: contain;
		}
	}

	@include link-transition(transform);
}

.c-image-grid__item-title {
	@include font-base-16;
	color: var(--secondary-a);
	line-height: lh(20, 16);
	margin-bottom: $ssu;
	margin-top: ($ssu * 1.5);
}

.c-image-grid__item-text-link,
.c-image-grid__item-link {

	&:hover,
	&:focus {
		.c-image-grid__item-title {
			color: var(--link--hover);
		}
	}
}
