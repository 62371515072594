﻿// CONTROL.PODS

$pod-bp: "medium";

.c-pod {
	height: 285px;
	display: flex;
	flex-direction: column;

	border-radius: 0;
	overflow: hidden;

	color: $white;
	color: var(--primary-b);

	@include link-transition;
	@include scroll-appear {
		opacity: 0;
		transform: translateY(100px);
	}
}

.c-pod__head {
	@include link-fancy__hover-parent;
	flex-grow: 1;
	display: block;
	isolation: isolate;
	position: relative;
	overflow: hidden;
	color: inherit;
	transition: all 0.3s;

	&:hover,
	&:focus {
		.c-pod__image {
			transform: scale(1.1);
		}

		.c-pod__title-text::after {
			opacity: 1;
		}

		.c-pod__figure:after {
			background-image: linear-gradient(0deg, var(--primary-a) 0%, var(--primary-a) 100%);
			opacity: 0.9;
			transition: all 0.3s;
		}
	}

	@include folded-corner();

	$sectionTheme: ('default','commercial', 'innovation-hub', 'retail', 'residential', 'green-spaces', 'inland-port', 'logistics', 'tgh');
	$sectionThemeColors: ($default, $commercial, $innovation-hub, $retail, $residential, $green-spaces, $inland-port, $logistics, $tgh);
	$base-color: zip($sectionTheme, $sectionThemeColors);

	@each $c in $base-color {
		&.section-theme-#{nth($c,1)} {
			@include folded-corner(nth($c,2));

			&:hover,
			&:focus {

				.c-pod__figure:after {
					background-image: linear-gradient(0deg, nth($c,2) 0%, nth($c,2) 100%);
					opacity: 0.9;
				}
			}
		}
	}
}

.c-pod__figure {
	display: block;
	height: 100%;

	position: relative;
	z-index: 1;

	&::after {
		@include link-transition(all, 0.3s);
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;

		width: 100%;
		height: 100%;

		background-image: linear-gradient(0deg, $black 0%, transparent 200px);
		background-image: linear-gradient(0deg, var(--secondary-a) 0%, transparent 200px);
	}
}

.c-pod__no-figure {
	&::after {
		background-image: none;
	}
}

.c-pod__image {
	@include link-transition;
	// Height inherited instead from pod
	.c-pod & {
		padding-bottom: 0;
		height: 100%;
	}
}

.c-pod__title {
	display: inline-block;
	margin: $ssu $bsu;
	padding: $ssu calc(1em + #{$tsu}) $ssu 0;

	position: absolute;
	z-index: 2;
	bottom: 0;
	left: 0;

	@include font-heading-20b;
	@include link-transition;
}

.c-pod__title-text {
	//@include link-arrow;
}
