// CONTROL.DRILLDOWN-PODS

$drilldown-pods-bp: "large";

.c-drilldown-pods {
	@include control;
}

.c-drilldown-pods__layout {
	@include layout-wrapper;
}

.c-drilldown-pods__heading {
	@include control__heading;
}

.c-drilldown-pods__list {
	@include list-reset;

	display: flex;
	flex-wrap: wrap;
	align-items: stretch;

	margin-left: -$bsu;
	margin-top: -$lsu;

	--grid-gutter-col: #{$bsu};
	--grid-gutter-row: #{$bsu};
	@include bp($drilldown-pods-bp) {
		--grid-gutter-row: #{$lsu};
		.l-page--has-sidebar .l-page__main-controls & {
			--grid-gutter-row: #{$bsu};
		}
	}
	margin-left: calc(-1 * var(--grid-gutter-col));
	margin-top: calc(-1 * var(--grid-gutter-row));
}

.c-drilldown-pods__list-item {
	padding-left: $bsu;
	padding-top: $lsu;

	padding-left: var(--grid-gutter-col);
	padding-top: var(--grid-gutter-row);

	width: 100%;
	@include bp("small") {
		width: 50%;
	}
	@include bp($drilldown-pods-bp) {
		width: 25%;
	}

	&.c-drilldown-pods__list-item--large {
		width: 100%;
		@include bp($drilldown-pods-bp) {
			width: 50%;
		}
	}

	.l-page--has-sidebar .l-page__main-controls & {
		@supports (display: grid) {
			width: 50%;
			&.c-drilldown-pods__list-item--large {
				width: 100%;
			}
		}
	}
}

.c-drilldown-pods__pod {
	height: 100%;
	display: flex;
	flex-direction: column;

	border-radius: 4px;
	overflow: hidden;

	color: $white;
	color: var(--primary-b);

	@include link-transition;
	@include scroll-appear {
		opacity: 0;
		transform: translateY(100px);
	}
}

.c-drilldown-pods__pod-head {
	@include link-fancy__hover-parent;

	flex-grow: 1;

	display: block;

	isolation: isolate;
	position: relative;
	overflow: hidden;

	color: inherit;

	&:hover,
	&:focus {
		.c-drilldown-pods__pod-image {
			transform: scale(1.1);
		}

		.c-drilldown-pods__pod-title-text::after {
			opacity: 1;
		}
	}
}

.c-drilldown-pods__pod-figure {
	display: block;
	height: 100%;

	position: relative;
	z-index: 1;

	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;

		width: 100%;
		height: 100%;

		background-image: linear-gradient(0deg, $black 0%, transparent 200px);
		background-image: linear-gradient(0deg, var(--primary-a) 0%, transparent 200px);
	}
}

.c-drilldown-pods__pod-image {
	@include link-transition;

	min-height: 100%;
}

.c-drilldown-pods__pod-title {
	display: inline-block;
	margin: $ssu $bsu;
	padding: $ssu calc(1em + #{$tsu}) $ssu 0;

	position: absolute;
	z-index: 2;
	bottom: 0;
	left: 0;

	@include font-heading-20b;
	@include link-transition;

}

.c-drilldown-pods__pod-title-text {
	@include link-arrow;
}

.c-drilldown-pods__pod-body {
	display: block;
	padding: $ssu $bsu $bsu;

	flex-shrink: 1;

	background: $black;
	background: var(--primary-a);
}

.c-drilldown-pods__pod-child-list {
	@include list-reset;
	display: flex;
	flex-wrap: wrap;

	margin-left: -$lsu;
	margin-top: -$ssu;

	--grid-gutter-col: #{$lsu};
	--grid-gutter-row: #{$ssu};
	margin-left: calc(-1 * var(--grid-gutter-col));
	margin-top: calc(-1 * var(--grid-gutter-row));
}

.c-drilldown-pods__pod-child-item {
	width: 100%;
	@include bp("medium") {
		width: 50%;
	}

	padding-left: $lsu;
	padding-top: $ssu;

	padding-left: var(--grid-gutter-col);
	padding-top: var(--grid-gutter-row);
}

.c-drilldown-pods__pod-child-link {
	display: block;

	@include font-heading-14b;
	color: inherit;

	&:hover,
	&:focus {
		.c-drilldown-pods__pods-child-link-text::after {
			opacity: 1;
		}
	}

	@include link-transition;

	@supports (background-color: var(--supporting-1-a)) {
		padding-left: $ssu + $tsu;
		position: relative;

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;

			height: 100%;
			width: 2px;

			background-color: var(--supporting-1-a);
		}
	}
}

.c-drilldown-pods__pods-child-link-text {
	@include link-arrow;
}
