// CONTROL.CASE-STUDY

@use "sass:math";

$case-study-bp: "large";

.c-case-study {
	@include control;
}

.c-case-study__layout {
	@include layout-wrapper;

	@include bp($case-study-bp) {
		@include flex-extend;
		align-items: stretch;
	}

	&.c-case-study__layout--right {
		@include bp($case-study-bp) {
			flex-direction: row-reverse;
		}
	}
}

.c-case-study__figure {
	@include bp($case-study-bp) {
		flex: 1 0 60%;
	}
}

.c-case-study__image {}

.c-case-study__body {
	@include font-body-text;
	padding: $bsu;
	background-color: $grey--lightest;
	border-radius: 0;
	background-image: url(/Content/images/interface/bg/motifSmallGrey.svg);
	background-position: bottom right;
	background-repeat: no-repeat;
	background-size: 250px;

	@include bp($case-study-bp) {
		flex: 1 0 40%;
		padding: $lsu;
		border-radius: 0;
		margin: $lsu 0 $lsu -#{$bsu * 1.5};
		z-index: 1;

		.c-case-study__layout--right & {
			margin: $lsu -#{$bsu * 1.5} $lsu 0;
		}
	}

	> :last-child {
		margin-bottom: 0;
	}
}

.c-case-study__heading {
	display: block;
	margin-bottom: $bsu;

	@include font-heading-20b;
	@include bp($case-study-bp) {
		@include font-heading-24b;
	}
}

.c-case-study__text {
	display: block;
	margin-bottom: $bsu;

	@include font-body-text;
}

.c-case-study__link {
	@include button("small");
	padding-left: $bsu;
	padding-right: $bsu;
	border: 1px solid var(--primary-b);

	&:hover,
	&:focus {
		border-color: var(--secondary-a);
	}
}

.c-case-study__video {
	display: block;
	cursor: pointer;

	padding-bottom: percentage(calc(9 / 16));
}

.c-case-study__video-icon {
	@include abs-center;
	@include square(56px);
	border-radius: 4px;
	@include link-transition;

	display: flex;
	align-items: center;
	justify-content: center;

	&::before {
		@include pseudo-element;
		content: $iconf-play;

		font-size: 24px;
	}
}

