﻿// CONTROL.SUBNAV

$selected-accent-width: 5px;

.c-subnav__heading {
	display: block;
	margin-bottom: $bsu;

	@include font-heading-20b;
	color: var(--secondary-a);
}

.c-subnav__items {
	@include font-base-14;
	@include ui-border('top');
}

.c-subnav__item {
	@include ui-border('bottom');
	background-color: $grey--lightest;

	&[aria-current]:not([aria-current="false"]) {
		position: relative;

		&::before {
			@include pseudo-element;
			left: 0;
			top: -1px;
			width: $selected-accent-width;
			height: calc(100% + 2px);
		}
	}
}

.c-subnav__link,
.c-subnav__child-link {
	@include link-transition;
	color: $body-color;

	&:hover,
	&:focus {
		color: $brand;
	}
}

.c-subnav__link {
	display: block;
	padding: $ssu $bsu;
	padding-left: $bsu + $selected-accent-width;

	[aria-current]:not([aria-current="false"]) > & {
		background-color: $white;
		@include font-base-14b;
	}

	@include link-transition;
	&:hover,
	&:focus {
		background-color: $grey--light;
	}
}

.c-subnav__children {
	padding: $ssu $bsu;
	padding-left: ($bsu * 1.5) + $selected-accent-width;
}

.c-subnav__child {
	margin-bottom: 0.75em;

	&:last-child {
		margin-bottom: 0;
	}
}

.c-subnav__child-link {
	color: $body-color;
}