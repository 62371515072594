$layout-page-bp: "large";

$layout-page-gutter: calc(max(0px, (var(--page-width) - #{$layout-wrapper-max-width}) / 2));

$layout-page-aside-column-width: calc(#{$layout-aside-gutter-width} + #{$layout-aside-width} + #{$layout-page-gutter} + #{$bsu});
$layout-page-aside-column-width-vars: calc(#{$layout-aside-gutter-width-vars} + #{$layout-aside-width} + #{$layout-page-gutter} + #{$bsu});

// Fallbacks first
// Be sure to override within @supports block if necessary
.l-page {
	padding-top: $lsu;
}

.l-page__hero {
	margin-top: -$lsu;

	margin-bottom: $bsu;
	@include bp($layout-page-bp) {
		margin-bottom: $lsu;
	}
}

.l-page__hero-inline {
	.l-page:not(.l-page--has-hero-inline) & {
		display: none;
	}

	margin-bottom: $bsu;
	@include bp($layout-page-bp) {
		margin-bottom: $lsu;
	}
}

.l-page__main-controls {
	&:last-child {
		margin-bottom: $lsu;
	}
}

.l-page__sidebar {
	@include control;

	margin-left: $bsu;
	margin-right: $bsu;

	width: $layout-aside-width;

	--layout-gutter-left: 0;
	--layout-gutter-right: 0;
}

.l-page__footer-controls {
	margin-top: var(--control-gap);
	&:last-child {
		margin-bottom: $lsu;
	}
	@include bp-below($layout-page-bp) {
		.l-page__sidebar + & {
			margin-top: 0;
		}
	}
}

.l-page__footer {
	margin-top: $bsu;
	@include bp($layout-page-bp) {
		margin-top: $lsu;
	}
}

@supports (display: grid) {
	.l-page {
		display: grid;
		grid-template-areas: "hero" "main-controls" "sidebar" "footer-controls" "footer";

		&.l-page--has-hero-inline {
			grid-template-areas: "hero" "hero-inline" "main-controls" "sidebar" "footer-controls" "footer";
		}

		@include bp($layout-page-bp) {
			grid-template-columns: 1fr;

			&.l-page--has-sidebar {
				grid-template-columns: 1fr $layout-page-aside-column-width;
				grid-template-columns: 1fr $layout-page-aside-column-width-vars;
				grid-template-areas: "hero hero" "main-controls sidebar" "footer-controls footer-controls" "footer footer";

				&.l-page--has-hero-inline {
					grid-template-rows: auto auto 1fr auto auto auto;
					grid-template-areas: "hero hero" "hero-inline sidebar" "main-controls sidebar" "footer-controls footer-controls" "footer footer";
				}
			}
		}
	}

	.l-page__hero {
		grid-area: hero;

		.c-hero {
			margin: 0;

			&.c-hero--video {
				position: relative;

				&::before {
					content: "";
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: linear-gradient(to bottom, $black 0%, rgba($black, 0.2 ) 30%, transparent 100% );
					z-index: 1;
				}

				@include bp("medium") {
					height: 90vh;
				}
			}
		}

		.c-page-header {
			margin-bottom: 0;
		}
	}

	.l-page__hero-inline {
		grid-area: hero-inline;

		.c-page-header {
			margin: 0;
		}

		.l-page--has-sidebar & {
			@include bp($layout-wrapper-max-width) {
				margin-left: $layout-page-gutter;
				--layout-gutter-right: 0;
			}
		}
	}

	.l-page__main-controls {
		grid-area: main-controls;

		.l-page--has-sidebar & {
			@include bp($layout-page-bp) {
				--layout-gutter-right: 0;
			}

			@include bp($layout-wrapper-max-width) {
				margin-left: $layout-page-gutter;
			}
		}
	}

	.l-page__footer-controls {
		grid-area: footer-controls;
	}

	.l-page__sidebar {
		grid-area: sidebar;

		@include bp($layout-page-bp) {
			margin-top: 0;
			margin-bottom: 0;
			width: $layout-aside-width;
		}

		width: auto;
		// If the sidebar is taller than the main controls,
		// ensure footer controls don't butt right up against it
		margin-bottom: var(--control-gap);

		@include bp($layout-page-bp) {
			margin-left: $layout-aside-gutter-width;
			margin-left: $layout-aside-gutter-width-vars;
		}

		@include bp($layout-wrapper-max-width) {
			margin-right: calc(#{$layout-page-gutter} + #{$bsu});
		}
	}

	.l-page__footer {
		grid-area: footer;
	}
}
