﻿.t-bg,
.o-fit {
	/*background-color: @Model.PrimaryColour;*/
}

.t-bg--rev {
	/*background-color: @Model.PrimaryColourContrast;*/
}

.t-bg--sec {
	/*background-color: @Model.SecondaryColour;*/
}

.t-bg__text {
	/*color: @Model.PrimaryColourContrast;*/
}

.t-text,
.t-bg__text--rev {
	/*color: @Model.PrimaryColour;*/
}

.t-text--sec,
.o-control__heading {
	/*color: @Model.SecondaryColour;*/
}

.t-bg__text--sec {
	/*color: @Model.SecondaryColourContrast;*/
}

.t-bg--sup-1 {
	/*background-color: @Model.SupportingColour1;*/
}

.t-bg__text--sup-1 {
	/*color: @Model.SupportingColour1Contrast;*/
}

.t-bg--rev--active:hover,
.t-bg--rev--active:focus {
	/*background-color: @Model.PrimaryColourContrast;*/
}

.t-bg__text--rev--active:hover,
.t-bg__text--rev--active:focus,
.t-hover-parent:hover .t-bg__text--rev--active,
.t-hover-parent:focus .t-bg__text--rev--active {
	/*color: @Model.SecondaryColour;*/
}

.t-bg--active:hover,
.t-bg--active:focus,
.t-hover-parent:hover .t-bg--active,
.t-hover-parent:focus .t-bg--active {
	/*background: @Model.SecondaryColour;*/
}

.t-bg__text--active:hover,
.t-bg__text--active:focus,
.t-hover-parent:hover .t-bg__text--active,
.t-hover-parent:focus .t-bg__text--active {
	/*color: @Model.SecondaryColourContrast;*/
}

.t-text--sec--active:hover,
.t-text--sec--active:focus,
.t-hover-parent:hover .t-text--sec--active,
.t-hover-parent:focus .t-text--sec--active {
	/*color: @Model.SecondaryColour;*/
}

.t-before-bg::before {
	/*background-color: @Model.PrimaryColour;*/
}

.t-before-bg--sec::before {
	/*background-color: @Model.SecondaryColour;*/
}

.t-before-bg--sup-1::before {
	/*background-color: @Model.SupportingColour1;*/
}

.t-before-text::before {
	/*color: @Model.PrimaryColour;*/
}

.t-after-bg::after,
.t-after-bg.is-selected::after {
	/*background-color: @Model.PrimaryColour;*/
}

.t-border-hover:focus,
.t-border-hover:hover,
.t-hover-parent:focus .t-border-hover,
.t-hover-parent:hover .t-border-hover {
	/*border-color: @Model.PrimaryColour;*/
}

.t-border-hover[aria-expanded="false"]:hover {
	/*border-color: @Model.PrimaryColour;*/
}

.t-link,
.t-link-parent a,
.o-body-text a,
.o-layout-header__lede a {
	/*color: @Model.LinkColour;*/
	/*transition: color 0.1s ease-out;*/
}

.t-link:focus,
.t-link:hover,
.t-hover-parent:hover .t-link,
.t-hover-parent:focus .t-link,
.t-link-parent a:focus,
.t-link-parent a:hover,
.o-body-text a:focus,
.o-body-text a:hover,
.o-layout-header__lede a:focus,
.o-layout-header__lede a:hover {
	/*color: @Model.LinkHoverColour;*/
}

.t-link--primary-hover:focus,
.t-link--primary-hover:hover,
.t-hover-parent:focus .t-link--primary-hover,
.t-hover-parent:hover .t-link--primary-hover {
	/*color: @Model.PrimaryColour;*/
}

.t-link-parent--plain a {
	/*color: @Model.PrimaryColourContrast;*/
}

.t-link-parent--plain a:hover,
.t-link-parent--plain a:focus {
	/*text-decoration: underline;*/
}

.t-link-parent--sec-plain a {
	/*color: @Model.SecondaryColourContrast;*/
}

.t-link-parent--sec-plain a:hover,
.t-link-parent--sec-plain a:focus {
	/*text-decoration: underline;*/
}

.t-link-parent--alt a {
	/*color: @Model.PrimaryColour;*/
}

/* Plugins - we don't want to change the class names */
.flickity-prev-next-button {
	/*background-color: @Model.PrimaryColour;*/
}

.flickity-button-icon {
	/*fill: @Model.PrimaryColourContrast;*/
}

/***********/
/* Actions */
/***********/
/* primary */
.t-action--primary,
.umbraco-forms-form .primary {
	border-radius: 4px;
/*	background-color: @Model.PrimaryColour;
	color: @Model.PrimaryColourContrast;*/
}

.t-action--primary:hover,
.t-action--primary:focus,
.umbraco-forms-form .primary:hover,
.umbraco-forms-form .primary:focus,
.t-hover-parent:hover .t-action--primary,
.t-hover-parent:focus .t-action--primary {
/*	background-color: @Model.SecondaryColour;
	color: @Model.SecondaryColourContrast;*/
}

/* secondary */
.t-action--secondary {
	border-radius: 4px;

/*	background-color: transparent;
	color: @Model.PrimaryColourContrast;
	box-shadow: inset 0 0 0 1px @Model.PrimaryColourContrast;*/
}

.t-action--secondary:hover,
.t-action--secondary:focus,
.t-hover-parent:hover .t-action--secondary,
.t-hover-parent:focus .t-action--secondary {
/*	background-color: @Model.SecondaryColour;
	color: @Model.SecondaryColourContrast;
	box-shadow: inset 0 0 0 1px @Model.SecondaryColour;*/
}

/* tertiary */
.t-action--tertiary {
	border-radius: 4px;

/*	background-color: @Model.SecondaryColour;
	color: @Model.SecondaryColourContrast;*/
}

.t-action--tertiary-outline {
	border-radius: 4px;

/*	background-color: @Model.SecondaryColour;
	color: @Model.SecondaryColourContrast;
	box-shadow: inset 0 0 0 1px @Model.SecondaryColourContrast;*/
}

.t-action--tertiary:hover,
.t-action--tertiary:focus,
.t-action--tertiary-outline:hover,
.t-action--tertiary-outline:focus,
.t-hover-parent:hover .t-action--tertiary,
.t-hover-parent:focus .t-action--tertiary {
/*	background-color: @Model.PrimaryColourContrast;
	color: @Model.PrimaryColour;
	box-shadow: inset 0 0 0 1px @Model.PrimaryColour;*/
}

/* outline */
.t-action--outline {
	border-radius: 4px;

/*	background-color: @Model.PrimaryColourContrast;
	color: @Model.PrimaryColour;
	box-shadow: inset 0 0 0 1px @Model.PrimaryColour;*/
}

.t-action--outline[aria-current]:not([aria-current="false"]) {
/*	background-color: @Model.PrimaryColour;
	color: @Model.PrimaryColourContrast;
	box-shadow: inset 0 0 0 1px @Model.PrimaryColour;
*/}

.t-action--outline-sec {
	border-radius: 4px;

/*	background-color: @Model.SecondaryColourContrast;
	color: @Model.SecondaryColour;
	box-shadow: inset 0 0 0 1px @Model.SecondaryColour;*/
}

.t-action--outline-sec[aria-current]:not([aria-current="false"]) {
	/*background-color: @Model.SecondaryColour;
	color: @Model.SecondaryColourContrast;
	box-shadow: inset 0 0 0 1px @Model.SecondaryColour;*/
}

.t-action--outline:hover,
.t-action--outline:focus,
.t-action--outline-sec:hover,
.t-action--outline-sec:focus,
.t-hover-parent:hover .t-action--outline,
.t-hover-parent:focus .t-action--outline {
/*	background-color: @Model.SecondaryColour;
	color: @Model.SecondaryColourContrast;
	box-shadow: inset 0 0 0 1px @Model.SecondaryColour;*/
}
