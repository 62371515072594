// OBJECT.CONTROL

@mixin control {
	@include bp-below("large") {
		margin-top: $lsu;
		margin-bottom: $lsu;
		margin-top: var(--control-gap);
		margin-bottom: var(--control-gap);
	}
	@include bp("large") {
		margin-top: $hsu;
		margin-bottom: $hsu;
		margin-top: var(--control-gap);
		margin-bottom: var(--control-gap);
	}

	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0;
	}
}
@mixin o-control { @include control; }

@mixin control__heading {
	display: block;
	margin-bottom: $bsu;
	@include bp("large") {
		margin-bottom: $lsu;
	}

	@include font-heading-20b;
	@include bp("medium") {
		@include font-heading-24b;
	}
	@include bp("large") {
		@include font-heading-28b;
	}
	color: var(--secondary-a);
}

@mixin control__figcaption {
	@include ui-border("bottom");
	@include font-body-text;
	padding: $bsu 0;
}

.o-control {
	@include o-control;
}

.o-control__heading {
	@include control__heading;
}

.o-control__figcaption {
	@include control__figcaption;
}
