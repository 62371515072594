@charset "UTF-8";

// For an explanation of the folder structure and namespacing check the wiki: https://terabyte.atlassian.net/wiki/spaces/TBWS/pages/94670004/CSS+Framework
$base-font-family: "Polaris", "polaris", Arial, Helvetica, sans-serif;
$heading-font-family: $base-font-family;

$energy--light-lime: #E5F75B;
$energy--lime: #BFD22B;
$energy--primary: $energy--lime;
$energy--dark-lime: #B5BD00;

//@import "../fonts/font-icons/base/variables";
@import "main";
//@import "../fonts/font-icons/base/style.scss";

// Overrides
@import "theme/ruakura-energy/energy.theme";

@import "theme/ruakura-energy/energy.object.btn";
@import "theme/ruakura-energy/energy.macro.quote";
@import "theme/ruakura-energy/energy.control.image-feature";
@import "theme/ruakura-energy/energy.control.cta";
@import "theme/ruakura-energy/energy.control.statistics";
@import "theme/ruakura-energy/energy.control.video";
@import "theme/ruakura-energy/energy.component.navigation";
@import "theme/ruakura-energy/energy.component.mega-menu";
@import "theme/ruakura-energy/energy.component.header";
@import "theme/ruakura-energy/energy.component.hero";
@import "theme/ruakura-energy/energy.component.home-intro";
@import "theme/ruakura-energy/energy.component.header-notification";
@import "theme/ruakura-energy/energy.component.footer";
@import "theme/ruakura-energy/energy.component.share";
@import "theme/ruakura-energy/energy.component.contact-page";
@import "theme/ruakura-energy/energy.control.our-story";
@import "theme/ruakura-energy/energy.control.tabs";
@import "theme/ruakura-energy/energy.control.news-listing";



// Custom components
