﻿// CONTROL.OUR-STORY

.c-our-story {
    @include flex-extend;
    flex-direction: column;
    padding: $lsu $bsu;
    background-color: var(--primary-a);
    position: relative;


    @include bp(1025px) {
        flex-direction: row;
        padding: ($hsu * 1.5) 0;
    }

    @include bp("extra-large") {
        //padding-bottom: 0;
    }

    @include bp(1600px) {
        &.has-both-sec__img{ 
            //padding-bottom: ($hsu * 1.5);
        }

        &.only-has-sec__img {
            padding-bottom: 400px;
        }
    }
}

.c-our-story__img-wrap {
    width: 100%;
    @include bp(1025px) {
        width: 50%;
    }
}

.c-our-story__figure {
    max-width: 850px;
    margin-right: $hsu;
    margin-left: -$bsu;

    @include bp(1025px) {
        margin-left: 0;
    }

    @include bp(1440px) {
        max-width: 800px;
    }
}

.c-our-story__takarangi {
    @include flex-extend;
    flex-direction: row-reverse;
    position: relative;
    top: -40px;
    margin-right: $bsu;

    @include bp("medium") {
        top: -80px;
    }

    @include bp(1025px) {
        margin-right: 0;
    }
}

.c-our-story__takarangi-img {
    max-width: 200px;

    @include bp("medium") {
        max-width: 400px;
    }

    @include bp(1025px) {
        max-width: 255px;
    }

    @include bp("extra-large") {
        max-width: 400px;
    }
}

.c-our-story__content-wrap {
    position: relative;
    padding: $lsu 0;

    @include bp("medium") {
        padding: $lsu $hsu 0;
    }

    @include bp(1025px) {
        padding: 0 $hsu;
        width: 50%;
        padding-right: $lsu;
    }

    @include bp(1600px) {
        padding-right: 0;
    }
}

.c-our-story__content {

    @include bp(1025px) {
        @include layout-split(right, 50%);
    }
}

.c-our-story__heading-wrap {
    margin-bottom: $lsu;

    @include bp(1025px) {
        margin-bottom: $hsu;
    }
}

.c-our-story__eyebrow {
    @include font-heading-18b;
    display: block;
    margin-bottom: $lsu;
}

.c-our-story__heading-mi {
    @include font-base-28b;
    color: var(--secondary-a);

    @include bp("medium") {
        @include font-base-36b;
    }

    @include bp(1025px) {
        @include font-base-45b;
    }
}

.c-our-story__heading {
    @include font-heading-28;
    color: var(--secondary-a);

    @include bp("medium") {
        @include font-heading-36;
    }

    @include bp(1025px) {
        @include font-heading-45b;
    }
}


.c-our-story__body {
    @include font-base-16;

    @include bp(1600px) {
        padding-left: 30%;
    }
}

.c-our-story__link {
    @include font-heading-18b;
    text-decoration: none;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: $bsu;

    &:hover,
    &:focus {
        .o-body-text &{
            color: var(--primary-b);
            text-decoration: none;
        }
    }
}

.c-our-story__content-img {
    width: 170px;
    height: 250px;
    position: absolute;
    bottom: 0;
    right: 0;
    display: none;

    .o-fit {
        height: 100%;
        padding-bottom: 0 !important;
    }

    @include bp(1600px) {
        display: block;
        bottom: 0;
    }
}