﻿.c-image-feature.o-theme-dark {
	.t-bg--sec {
		background-color: $carcoal;
	}

	.c-image-feature__heading {
		color: $white;
		&:before {
			background-color: var(--link--hover);
		}
	}

	.c-image-feature__text {
		color: $white;
	}
	
	.c-image-feature__link.t-action--primary:hover {
		background-color: var(--link--hover);
		color: $black;
	}
}