// COMPONENT.PAGINATION

.c-pagination {
	@include flex-extend;
	@include font-base-16b;
	justify-content: center;
	align-items: center;
	margin-top: $bsu;
	margin-bottom: $bsu;
}

.c-pagination__desktop {
	flex-grow: 1;

	.c-pagination {
		margin-top: 0;
		margin-bottom: 0;
	}
}

.c-pagination__status {
	@include font-base-16;
}

.c-pagination__current {
	padding: 0 $bsu;

	@include bp("medium") {
		padding: 0 $lsu;
	}
}

.c-pagination__dropdown {
	padding: $ssu;
	margin: 0 $tsu;
}

// "&, a" because the class is sometimes on an <li> and sometimes on an <a>
.c-pagination__prev,
.c-pagination__number,
.c-pagination__next {
	&, a {
		@include link-transition;
		@include font-heading-16b;
		padding: 0 $tsu;
		white-space: nowrap;

		:hover,
		:focus {
			text-decoration: underline;
		}
	}

	&:first-child {
		&, a {
			padding-left: 0;
		}
	}

	&:last-child {
		&, a {
			padding-right: 0;
		}
	}
}

.c-pagination__icon {
	display: inline-block;
	vertical-align: middle;

	.c-pagination__prev & {
		margin-right: $tsu;
	}
	.c-pagination__next & {
		margin-left: $tsu;
	}
}

.c-pagination__nav-text {
	display: none;

	@include bp("small") {
		display: inline;
	}
}
