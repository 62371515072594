// COMPONENT.FOOTER

@use "sass:math";

$footer-top-bp: "medium";
$footer-bottom-bp: "medium";

$footer-padding-left: 200px;

.c-footer {
	background: $supporting-1-a;
	color: $supporting-1-b;
	background: var(--supporting-1-a);
	color: var(--supporting-1-b);

	overflow:hidden;
}

//////////////////////
// FOOTER CONTAINER //
/////////////////////

.c-footer__container {
	@include layout-wrapper;
	@include flex-extend;
	flex-direction: column;

	background: $supporting-1-a;
	color: $supporting-1-b;
	background: var(--supporting-1-a);
	color: var(--supporting-1-b);
}


////////////////
// FOOTER TOP //
////////////////

.c-footer__top {
	position: relative;
}

.c-footer__top-layout {

	@include bp($footer-top-bp) {
		//background: url("/content/images/interface/bg/stairs.svg") no-repeat center right;
	}

	@include bp("large") {
		&::after {
			width: 40vw;
			content: " ";
			display: block;
			height: 100%;
			background-color: var(--secondary-a);
			right: -20vw;
			top: 0;
			position: absolute;
			z-index: 0;
		}
	}

	@include bp("extra-large") {
		&::after {
			width: 60vw;
		}
	}
}

.c-footer__top-container {
	@include layout-wrapper;
	padding-top: ($bsu + $tsu);
	padding-bottom: ($bsu + $tsu);

	@include flex-extend;

	@include bp-below($footer-top-bp) {
		flex-direction: column;
		text-align: center;
	}

	align-items: center;
	width: 100%;
	overflow: hidden;
	position: relative;
	flex-wrap: wrap;

	@include bp("large") {
		padding-left: calc($footer-padding-left / 2);
		align-items: flex-end;

		&::before {
			width: 140px;
			height: 200%;
			background: black;
			content: " ";
			display: block;
			position: absolute;
			transform: rotate( 316deg);
			top: 0;
			left: $ssu;
			transform-origin: bottom left;
			z-index: 1;
		}
	}

	@include bp("extra-large") {
		padding-left: $footer-padding-left;
	}
}

.c-footer__graphic {
	@include layout-wrapper;
	position: relative;
	display: none;

	@include bp("large") {
		display: block;
	}
}

.c-footer__graphic__img {
	@include bp("large") {
		position: absolute;
		z-index: 2;
		left: -#{($footer-padding-left + $lsu)};
		max-width:330px;
	}

	@include bp("extra-large") {
		left: -$footer-padding-left;
	}
}

.c-footer__top-text {
	display: block;
	position: relative;
	z-index: 1;

	@include bp-below($footer-top-bp) {
		margin-bottom: $bsu;
		text-align: center;
	}

	@include bp($footer-top-bp) {
		margin-right: $hsu;
	}

	@include font-heading-18b;

	@include bp($footer-top-bp) {
		@include font-heading-20b;
	}
}

.c-footer__top-text-mi {
	@include font-base-14i;
	display: block;
	margin-bottom: $ssu;
}

.c-footer__top-button {
	white-space: nowrap;

	@include bp-below($footer-top-bp) {
		@include button("small");
		padding: $ssu $lsu;
	}

	@include bp($footer-top-bp) {
		@include button("small");
		padding: $ssu $lsu;
	}
}

///////////////////
// FOOTER BOTTOM //
///////////////////

.c-footer__bottom {
	background: $supporting-1-a;
	color: $supporting-1-b;
	background: var(--supporting-1-a);
	color: var(--supporting-1-b);

	@include bp-below($footer-bottom-bp) {
		padding: ($bsu * 1.5) 0;
	}

	@include bp($footer-bottom-bp) {
		padding: $hsu 0;
	}

	@include bp("large") {
		padding: $hsu 0 $lsu;
	}
}

.c-footer__bottom-layout {
	@include layout-wrapper;

	@include bp("large") {
		padding-left: calc($footer-padding-left / 2);
	}

	@include bp("extra-large") {
		padding-left: $footer-padding-left;
	}
}

.c-footer__bottom-container {

	display: grid;
	grid-template-areas:
		"logos"
		"btns"
		"legal";

	grid-row-gap: $bsu;
	grid-column-gap: $lsu;

	@include bp($footer-bottom-bp) {
		grid-template-areas:
			"logos logos"
			"btns btns"
			"legal legal";

		grid-row-gap: $lsu;
	}

	@include bp("large") {
		grid-template-columns: 50%;
		grid-template-areas:
			"logos btns"
			"legal legal";
	}

	@include bp("extra-large") {
		grid-template-columns: inherit;
	}

}

// Logo List //
.c-footer__logo-items {
	grid-area: logos;
	display: flex;
	justify-content: center;
	align-items: center;

	@include bp("large") {
		justify-content: flex-start;
	}
}

.c-footer__logo-item {
	max-width: 90px;
	margin-right: $bsu;

	&:last-child {
		margin-right: 0;
	}

	@include bp("medium") {
		max-width: unquote("min(130px, 100%)");
	}

	@include bp("large") {
		margin-right: $lsu;
	}

	@include bp("extra-large") {
		width: auto;
		max-width: 140px;
	}
}

.c-footer__logo-img {
	max-width: 100%;
	max-height: 70px;
}

// Button List //
.c-footer__btn-list {
	grid-area: btns;
	display: flex;
	align-items: baseline;
	flex-direction: column;

	@include bp("medium") {
		flex-direction: row;
		justify-content: center;
	}

	@include bp("large") {
		justify-content: flex-end;
	}
}

.c-footer__btn {
	margin-right: $bsu;
	margin-bottom: $bsu;

	&:last-child {
		margin-right: 0;
	}

	@include bp-below($footer-top-bp) {
		@include button("small");
		padding: $ssu $lsu;
		width: 100%;
		margin-right: 0;
	}

	@include bp("large") {
		@include button("large");
	}
}

// Address //
.c-footer__address {
	grid-area: address;
	a {
		color: inherit;
		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	display: flex;
	flex-wrap: wrap;
	margin-left: -$lsu;
	margin-top: -$bsu;
}

.c-footer__address-item {
	padding-left: $lsu;
	padding-top: $bsu;
	@include bp-below($footer-bottom-bp) {
		width: 50%;
	}
}

.c-footer__address-item-title {
	display: block;
	margin-bottom: $bsu;

	@include font-heading-20b;
}

.c-footer__address-item-text {
	@include font-base-14;

	> :last-child {
		margin-bottom: 0;
	}
}

// Social //
.c-footer__social {
	grid-area: social;
	display: flex;
	position: relative;
	z-index: 1;

	@include bp-below($footer-bottom-bp) {
		justify-content: center;
		margin-top: $lsu;
	}

	@include bp($footer-bottom-bp) {
		justify-content: flex-end;
		margin-left: $bsu;
	}

	@include bp("large") {
		margin-top: 0;
		margin-left: auto;
	}
}

.c-footer__social-list {
	@include list-reset;
}

.c-footer__social-item {
	display: inline-block;
	margin-right: $bsu;

	&:last-child {
		margin-right:0;
	}
}

.c-footer__social-link {
	@include circle(40px);
	@include link-transition;
	border: 1px solid currentColor;
	font-size: 0px;

	&:before {
		font-size: 20px;
	}
}

// Legal //
.c-footer__legal {
	grid-area: legal;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;

	@include bp("medium") {
		justify-content: center;
	}

	@include bp("large") {
		justify-content: flex-end;
	}
}

.c-footer__legal-links {
	@include list-reset;

	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;

	@include bp-below($footer-bottom-bp) {
		justify-content: flex-start;
		margin: 0 #{-$bsu};
	}
}

.c-footer__legal-links-item {
	margin-bottom: $ssu;
	margin-right: $bsu;

	@include bp-below($footer-bottom-bp) {
		margin-left: $bsu;
	}

	&:first-child {
		width: 100%;

		@include bp($footer-bottom-bp) {
			width: auto;
		}
	}

	&:last-child {
		@include bp($footer-bottom-bp) {
			margin-right: 0;
		}
	}
}

.c-footer__legal-link {
	display: block;

	@include font-heading-10b;
	color: inherit;

	&:focus,
	&:hover {
		text-decoration: underline;
	}
}

.c-footer__tb {
	display: block;

	@include font-heading-10b;
	color: inherit;

	&:focus,
	&:hover {
		text-decoration: underline;
	}
}

.c-footer__legal-copyright {
	display: block;

	@include font-heading-10;
}

// Back to top //
.c-footer-back-to-top {
	@include button("small", true);
	@include bp("large") {
		@include button-size("medium", true);
	}

	display: block;
	position: fixed;
	bottom: 30px;
	right: 0;
	z-index: $z-back-to-top;

	opacity: 0;
	visibility: hidden;
	transition: opacity 0.5s linear, visibility 0s linear 0.5s;
	&.fade-in {
		opacity: 1;
		visibility: visible;
		transition-delay: 0s;
	}

	@include bp("large") {
		position: relative;
		display: none;
		bottom: 0;
		vertical-align: bottom;
		opacity: 1;
		visibility: visible;

		&.sticky-on-desktop {
			display: inline-block;
			opacity: 0;
			visibility: hidden;

			&.fade-in {
				position: fixed;
				bottom: auto;
				top: 50%;

				transform: translateY(-50%);
				opacity: 1;
				visibility: visible;
			}
		}
	}
}
