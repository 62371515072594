﻿// CONTROL.EXPAND-COLLAPSE

$expand-collapse-bp: "large";

.c-expand-collapse {
	@include control;
}

.c-expand-collapse__layout {
	@include layout-wrapper;
}

.c-expand-collapse__layout-inner {
	max-width: $layout-wrapper-narrow-width - $bsu * 2;
	max-width: calc($layout-wrapper-narrow-width-vars - #{$bsu * 2});
	margin-left: auto;
	margin-right: auto;
}

.c-expand-collapse__heading {
	@include control__heading;
}
.c-expand-collapse__heading {
	margin-bottom: $bsu;
}

.c-expand-collapse__item-header {
	@include link-transition;
	position: relative;
	padding: ($ssu * 1.5) $bsu;
	padding-right: $bsu * 3;
	background-color: $white;
	cursor: pointer;
	outline: none;

	&:hover,
	&:focus {
		color: $brand;
	}
}

.c-expand-collapse__item-btn {
	@include button;
	@include button--text;

	display: flex;
	margin: $ssu 0 0;

	justify-content: center;
	align-items: center;

	padding: $ssu 0;
	@include link-fancy(after, #{22px + $ssu});
}

.c-expand-collapse__item-btn-icon {
	margin-right: $ssu;

	font-size: 22px;

	.c-expand-collapse__item.is-expanded &,
	.c-expand-collapse__item[aria-expanded="true"] & {
		&::before {
			content: $iconf-minus;
		}
	}
}

.c-expand-collapse__item-btn-text {
	@include bp-below($expand-collapse-bp) {
		@include font-heading-14b;
	}
	@include bp($expand-collapse-bp) {
		@include font-heading-16b;
	}
}

.c-expand-collapse__item-btn--open {
	.c-expand-collapse__item[aria-expanded="true"] & {
		display: none;
	}
}

.c-expand-collapse__item-btn--close {
	.c-expand-collapse__item:not([aria-expanded="true"]) & {
		display: none;
	}
}

.c-expand-collapse__item-body {
	padding: $ssu 0;

	.js & {
		display: none;
	}

	.c-expand-collapse__item.is-expanded &,
	.c-expand-collapse__item[aria-expanded="true"] & {
		display: block;
	}
}
