// CONTROL.EXPLORE-PODS

@use "sass:math";

@mixin explore-pods-wide {
	.c-explore-pods__pod-image-fallback,
	.c-explore-pods__pod-image {
		padding-bottom: percentage(calc(175 / 360));
		@include bp("large") {
			padding-bottom: percentage(calc(40 / 63));
		}
	}
	.c-explore-pods__pod-image--wide {
		display: block;
	}
	.c-explore-pods__pod-image--squat {
		display: none;
	}
}

@mixin explore-pods-squat {
	.c-explore-pods__pod-image-fallback,
	.c-explore-pods__pod-image {
		padding-bottom: 100%; // Square
		@include bp("medium") {
			padding-bottom: percentage(calc(23 / 30));
		}
	}
	.c-explore-pods__pod-image--wide {
		display: none;
	}
	.c-explore-pods__pod-image--squat {
		display: block;
	}
}

@mixin explore-pods-appear {
	@media screen and (prefers-reduced-motion: no-preference) {
		.c-explore-pods__pod,
		.c-explore-pods__cta {
			@content;

			opacity: 0;
			@include link-transition(all, 0.5s);
		}

		&[data-scroll-appear="true"] {
			.c-explore-pods__pod,
			.c-explore-pods__cta {
				opacity: 1;
				transform: none;
			}
		}
	}
}

.c-explore-pods {
	@include control;
}

.c-explore-pods__layout {
	@include layout-wrapper;
}

.c-explore-pods__top {
	@include flex-extend;
	flex-wrap: wrap;
	margin-left: -$bsu;
	margin-top: -$bsu;

	@include bp("large") {
		margin-left: -$lsu;
		margin-top: -$lsu;
	}

	justify-content: space-between;

	.c-explore-pods__heading {
		display: block;
		width: 100%;
		padding-left: $bsu;
		margin-bottom: $bsu;

		@include bp("large") {
			padding-left: $lsu;
		}

		@include font-heading-24b;

		@include bp("medium") {
			@include font-heading-28b;
		}

		@include bp("large") {
			@include font-heading-28b;
		}
	}

	.c-explore-pods__heading-mi {
		@include font-heading-13i;
		display:inline-block;
		margin-bottom: $tsu;
	}

	.c-explore-pods__item {
		@include flex-extend;
		align-items: flex-end;
		@include explore-pods-squat;
	}

	.c-explore-pods__pod {
		width: 100%;
		height: auto;

		@include bp("large") {
			height: 230px;
		}
	}

	.c-explore-pods__item {
		width: percentage(calc(1 / 2));
	}

	.c-explore-pods__head {
		width: percentage(calc(1 / 1));
	}

	@include bp("extra-large") {
		.c-explore-pods__item {
			width: percentage(calc(1 / 4));
		}

		.c-explore-pods__head {
			width: percentage(calc(1 / 2));
		}
	}
}

.c-explore-pods__bottom {
	@include flex-extend;
	align-items: flex-start;
	flex-wrap: wrap;
	margin-left: -$bsu;
	margin-top: (-$bsu + $bsu);

	@include bp("large") {
		margin-left: -$lsu;
		margin-top: (-$lsu + $lsu);
	}

	@include explore-pods-squat;

	.c-explore-pods__item {
		width: percentage(calc(1 / 2));
	}

	@include bp("large") {
		.c-explore-pods__item {
			width: percentage(calc(1 / 4));

			&:nth-child(2) {
				width: percentage(calc(1 / 2));
				@include explore-pods-wide;

				.c-explore-pods__pod {
					height: auto;

					@include bp("large") {
						height: 450px;
					}
				}
			}
		}
	}
}

.c-explore-pods__head {
	flex-grow: 1;

	padding-left: $bsu;
	padding-top: $bsu;
	@include bp("large") {
		padding-left: $lsu;
		padding-top: $lsu;
	}
}

.c-explore-pods__head-content {
	width: 100%;
	@include bp("extra-large") {
		margin: 0 auto;
	}
}

.c-explore-pods__introduction {}

.c-explore-pods__introduction-links {
	display: flex;
	margin-top: $bsu;
}

.c-explore-pods__introduction-link {
	display: flex;
	align-items: center;
	padding-top: $tsu;
	padding-bottom: $ssu;
	margin-top: #{$ssu - $tsu};

	@include font-heading-14b;
	@include bp("large") {
		@include font-heading-16b;
	}

	margin-right: $lsu;
	&:last-child {
		margin-right: 0;
	}

	@include link-fancy(before);
	&::after {
		@include font-icon;
		content: $iconf-arrow-right;

		display: inline-block;
		margin-left: $ssu;
	}
}

.c-explore-pods__introduction-text {
	@include body-text;
}

.c-explore-pods__item {
	padding-left: $bsu;
	padding-top: $bsu;
	@include bp("large") {
		padding-left: $lsu;
		padding-top: $lsu;
	}

	@include explore-pods-appear {
		transform: translateY(100px);
	}
}


// Pod
.c-explore-pods__pod {
	display: block;
	position: relative;
	height: 100%;
	border-radius: 0;
	overflow: hidden;
	// Create stacking context
	filter: grayscale(0);

	@include bp("large") {
		height: 230px;
	}

	&:hover,
	&:focus {
		.c-explore-pods__pod-image {
			transform: scale(1.1);
		}

		.c-explore-pods__pod-title-text::after {
			opacity: 1;
		}

		.c-explore-pods__pod-shadow {
			@include link-transition(all, 0.3s);
			height: 100%;
			background: var(--primary-a);
			opacity: 0.9;
		}
	}

	@include folded-corner();

	$sectionTheme: ('default','commercial', 'innovation-hub', 'retail', 'residential', 'green-spaces', 'inland-port', 'logistics', 'tgh');
	$sectionThemeColors: ($default, $commercial, $innovation-hub, $retail, $residential, $green-spaces, $inland-port, $logistics, $tgh);
	$base-color: zip($sectionTheme, $sectionThemeColors);

	@each $c in $base-color {
		&.section-theme-#{nth($c,1)} {
			@include folded-corner(nth($c,2));

			&:hover,
			&:focus {
				.c-explore-pods__pod-shadow {
					background: nth($c,2);
				}
			}
		}
	}
}

.c-explore-pods__pod-image {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;

	position: relative;
	z-index: 1;

	@include link-transition(transform);
}

.c-explore-pods__pod-shadow {
	content: "";
	position: absolute;
	z-index: 2;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 150px;

	background: linear-gradient(to top, rgba($black, 0.95) 0%, rgba($grey--darkest, 0) 100%);
	background: linear-gradient(to top, var(--secondary-a) 0%, transparent 100%);
}

.c-explore-pods__pod-image-fallback {
	background: $grey--dark;
	height: 100%;
}

.c-explore-pods__pod-body {
	position: absolute;
	z-index: 3;
	bottom: 0;
	left: 0;
	width: 100%;

	color: $white;
	padding: $ssu;
	@include bp("medium") {
		padding: $bsu;
	}
}

.c-explore-pods__pod-title {
	display: block;
	padding-right: calc(1em + #{$tsu});
	margin-top: $bsu;

	@include font-heading-18b;
	@include bp("large") {
		@include font-heading-22b;
	}
}

.c-explore-pods__pod-title-text {
	@include link-arrow;
}

/////////////////
// Grid layout //
/////////////////
@supports (display: grid) {
	@include explore-pods-wide;

	.c-explore-pods__top {
		display: grid;
		margin: 0;
		grid-gap: $ssu;

		@include bp("medium") {
			grid-gap: $bsu;
		}

		@include bp("large") {
			grid-gap: $lsu;
		}

		.c-explore-pods__heading,
		.c-explore-pods__item,
		.c-explore-pods__head {
			width: 100%;
			padding: 0;
		}

		grid-template-columns: 1fr 1fr;
		grid-template-areas: "head head" ". .";

		@include bp("extra-large") {
			grid-template-columns: 1fr 1fr 2fr;
			grid-template-areas: ". . head";
		}

		.c-explore-pods__heading {
			grid-area: heading;
		}

		.c-explore-pods__head {
			grid-area: head;
		}
	}

	.c-explore-pods__bottom {
		display: grid;
		grid-gap: $ssu;
		margin: $ssu 0 0;

		@include bp("medium") {
			grid-gap: $bsu;
			margin-top: $bsu;
		}

		@include bp("large") {
			grid-gap: $lsu;
			margin-top: $lsu;
		}

		.c-explore-pods__item {
			&,
			&:nth-child(2) {
				width: 100%;

				.c-explore-pods__pod {

					@include bp("large") {
						height: 450px;
					}
				}
			}

			padding: 0;
			width: auto;

			.c-explore-pods__pod {
				height: auto;

				@include bp("large") {
					height: 230px;
				}
			}
		}

		grid-template-columns: 1fr 1fr;

		.c-explore-pods__item {
			&:nth-child(3) {
				grid-column-start: 1;
				grid-column-end: span 2;
				@include explore-pods-wide;

				.c-explore-pods__pod {
					height: 230px;
				}
			}
		}

		@include bp("large") {
			grid-template-columns: 1.5fr 2fr 1fr;

			.c-explore-pods__item {
				&:nth-child(1) {
					grid-column-start: 1;
					grid-column-end: span 1;
					grid-row-start: 1;
					grid-row-end: span 1;
				}

				&:nth-child(2) {
					grid-column-start: 2;
					grid-column-end: span 1;
					grid-row-start: 1;
					grid-row-end: span 2;
				}

				&:nth-child(3) {
					grid-column-start: 3;
					grid-column-end: span 1;
					grid-row-start: 1;
					grid-row-end: span 1;
				}


				&:nth-child(3) {
					@include explore-pods-squat;
				}

				&:nth-child(1),
				&:nth-child(2) {
					@include explore-pods-wide;
				}
			}
		}
	}
}
