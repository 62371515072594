﻿
.holding-page-body {
	height: 100vh;
	text-align: center;
	margin: 0px;
	padding: 0px;
	background: url("https://www.tgh.co.nz/media/5a2hkaxb/ruakuraenergy.jpeg") no-repeat top center;
	background-size: cover;
	position: relative;

	&:before {
		content: '';
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background: rgb(0,0,0);
		z-index: 1;
		background: linear-gradient(147deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%);
	}

	h1 {
		@include font-heading-28b;
		text-shadow: none;
		color: $white;

		@include bp('large') {
			@include font-heading-60b;
		}
	}

	.c-header__global-nav {
		background: #BFD22B;
		position: relative;
		z-index: 10;
		display: block;
		height: auto;
	}

	.c-header__global-nav-layout {
		@include bp-below('large') {
			padding: 0;
		}
	}

	.c-header__global-nav-link {
		color: $body-color;
	}
}

.holding-page-content {
	padding: 20px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: $white;
	width: 90%;
	z-index: 10;
}

.site-logo {
	display: block;
	margin: auto;
	/* margin: 163px auto 135px; */
	margin-bottom: 120px;
	width: 300px;
}


.holding-page__header {
	position: relative;
	text-align: center;
	display: inline-block;
}
