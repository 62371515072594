﻿.c-header__logo {
	@include bp('large') {
		margin-bottom: 0;
	}
}

.c-header__tools {
	order: 4;

	.t-bg__text {
		color: $white;
	}
}

.c-header__secondary-nav-wrapper {
	flex-direction: row;
	flex: 1 1 100%;
	justify-content: flex-end;
	align-items: center;

	a {
		@include link-fancy;
		color: var(--primary-a);
		padding: $ssu;

		&:before {
			vertical-align: middle;
		}

		span {
			@include font-heading-12b;
			margin-left: $ssu;
		}
	}

	@include bp('large') {
		order: -1;
	}
}

.c-header__secondary-nav {
	margin-top: 0;

	.c-header__secondary-nav-link {
		color: $white;
	}
}