// COMPONENT.HERO

@use "sass:math";

$hero-bp: "extra-large";

$hero__slide-body-width: 600px;

.c-hero {
	position: relative;
	margin-bottom: $lsu;
}

.c-hero__heading-mi {
	@include font-heading-20i;
	color: $white;
	text-shadow: 0 0 5px $black;
	margin: $bsu 0;
	display: block;
}

.c-hero__heading {
	position: absolute;
	top: calc(50% + 32px);
	left: 0;
	transform: translate(-50%, 0%);

	@include font-heading-28;

	@include bp("medium") {
		@include font-heading-32;
	}

	@include bp($hero-bp) {
		@include font-heading-60;
	}

	color: $white;
	text-shadow: 0 0 5px $black;
}

.c-hero__heading-layout {
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	transform: translateY(-50%);
	margin-top: $bsu;
	z-index: 1;
}

.c-hero__heading-wrapper {
	@include layout-wrapper;
}

.c-hero__heading {
	position: static;
	transform: none;
}

.c-hero__button {
	@include bp-below("medium") {
		@include button("small");
	}

	@include bp("medium") {
		@include button("medium");
	}

	@include bp($hero-bp) {
		@include button("large");
	}

	display: inline-block;
	margin-right: $bsu;
	margin-bottom: $bsu;
	z-index: 1;
}

/////////////
// PRIMARY //
/////////////

.c-hero__primary {
	margin: 0 0 $bsu 0;
	position: relative;
	z-index: 1;

	@include flex-extend;
	flex-direction: column-reverse;

	@include bp($hero-bp) {
		flex-direction: row;
		align-items: stretch;
	}
}

.c-hero__body {
	@include flex-extend;
	align-items: center;
	padding: $bsu;

	@include bp($hero-bp) {
		width: 100%;
		min-height: 200px;

		.c-hero--has-image & {
			width: 50%;
			min-height: 540px;
			padding-left: 0;
			padding-right: 0;
		}
		// Offset from image
		margin-bottom: $lsu;
	}
}

.c-hero__body-content {
	flex-direction: column;
	padding: $bsu;

	@include bp-below($hero-bp) {
		.c-content-type-ruakurainlandporthomepage & {
			width: 100%;
		}
	}

	@include bp($hero-bp) {
		@include layout-wrapper;

		.c-hero--has-image & {
			max-width: none;
			padding: 0;

			@include bp-below($layout-wrapper-max-width) {
				padding-left: $bsu;
			}

			@include layout-split(left, 50%);
			padding-right: $lsu;
		}
	}
}

.c-hero__title {
	@include font-heading-28;

	@include bp("medium") {
		@include font-heading-32;
	}

	@include bp-below($hero-bp) {
		.c-content-type-ruakurainlandporthomepage & {
			width: 100%;
			text-align: center;
		}
	}

	@include bp("large") {
		@include font-heading-36;
	}

	position: relative;
	z-index: 2;
	margin-bottom: $bsu;
	text-shadow: 0 2px 8px rgba($black, 0.2);

	@include bp($hero-bp) {
		@include font-heading-72;
		margin-bottom: $lsu;
	}
}

.c-hero__text {
	@include font-base-18;

	@include bp-below("medium") {
		display: none;
	}

	@include bp($hero-bp) {
		flex-grow: 1;
	}
}

.c-hero__date {
	@include font-base-12;
	margin-bottom: 2em;
}

.c-hero__scroll-icon {
	margin-top: $lsu;
	color: $red;
	text-align: center;
	font-size: 24px;
	display: none;

	@include bp($hero-bp) {
		display: block;
	}
}

.c-hero__figure {
	@include figure;
	flex-grow: 1;
	position: relative;
	overflow: hidden;
	max-height: 80vh;
	//max-height: unquote("min(440px, 80vh)");
	@include bp($hero-bp) {
		max-height: none;
	}

	&::before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(to bottom, $black 0%, rgba($black, 0.2 ) 30%, transparent 100% );
		z-index: 1;
	}

	.c-hero--image & {
		height: 300px;

		@include bp($hero-bp) {
			height: 440px;
		}
	}
}

.c-hero__image {
	display: block;
	width: 100%;

	.c-hero--image & {
		height: 100%;
		padding: 0 !important;
		object-fit: cover;
	}
}

.c-hero__video {
	display: none;

	@include bp("medium") {
		display: block;
		width: 100%;
		height: 100%;
		max-height: 100vh;
		object-fit: cover;
		position: relative;

		~ .c-hero__image {
			display: none;
		}
	}
}

.c-hero__video-icon_wrapper {
	display: none;

	@include bp("medium") {
		display: block;
		position: absolute;
		text-align: center;
		width: 100%;
		bottom: ($bsu * 1.5);
	}
}
///////////////
// SECONDARY //
///////////////
.c-hero__secondary {
	margin: ($bsu * 3) 0 0; // Match .o-layout-page
}

.c-hero__secondary-title {
	width: 100%;
	text-align: center;
}

.c-hero__secondary-title-text {
	display: block;
	color: $brand;
	margin-bottom: $bsu;

	@include bp($hero-bp) {
		padding: 0 20px;
		margin-bottom: 0;
	}
}

.c-hero__secondary-introduction {
	@include font-base-18;
	flex: 0 1 auto;

	@include bp($hero-bp) {
		margin-top: 4px; // Align better with secondary title
		margin-left: 40px;
	}
}

//////////////
// HOMEPAGE //
//////////////

.c-hero--homepage {
	.c-hero__primary {
		margin-bottom: 0;
		margin-left: 0;
		margin-right: 0;
		width: 100%;

		.c-content-type-ruakurainlandporthomepage & {
			max-height: 760px;
		}

		@include bp-below($hero-bp) {
			flex-direction: column;
		}

		@include bp("extra-large") {
			height: 90vh;
			min-height: 600px;
		}

		&.c-hero__video-wrapper {
			@include bp("medium") {
				height: 80vh;
			}

			@include bp("extra-large") {
				height: 90vh;
			}
		}
	}

	.c-hero__body {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 1;

		@include bp-below("large") {
			//overflow: hidden;
			padding: $bsu;
		}

		@include bp("large") {
			margin-bottom: 160px;
		}
	}

	.c-hero__body-content {
		height: 100%;
		display: flex;
		position: relative;

		@include bp-below("large") {
			padding: 0;
		}
	}

	.c-hero__body-content-top {
		margin: auto 0 $lsu;

		.c-content-type-ruakurainlandporthomepage & {
			margin-bottom: auto;
		}

		width: 100%;
		padding-top: $header-height;
		padding-top: var(--header-height);

		@include bp("large") {
			width: 50%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			position: relative;
			min-height: 350px;
		}

		@include bp("extra-large") {
			min-height: 450px;
		}
	}

	.c-hero__body-content-bottom {
		margin-top: auto;
		margin-left: auto;
		display: flex;
		width: 100%;
		margin-bottom: -50px;
		z-index: 1;

		@include bp("medium") {
			width: 70%;
		}

		@include bp("large") {
			margin-right: -#{$bsu};
			margin-bottom: -130px;
		}

		@include bp("extra-large") {
			width: 50%;
		}
	}

	.c-hero__body-pods {
		width: 100%;
		grid-template-columns: repeat(2, 1fr);
	}


	.c-hero__figure {
		padding-bottom: 100%;

		@include bp("medium") {
			padding-bottom: 0;
		}

		&::before {
			background: linear-gradient(to bottom, $black 0%, rgba($black, 0.2 ) 15%, transparent 20% );
		}
	}

	.c-hero__image {
		position: absolute;
		height: 100%;
		object-fit: cover;

		@include bp("medium") {
			position: static;
			height: auto;
		}
	}

	.c-hero__title {
		text-shadow: 0 0 7px rgba($black, 0.9);
	}

	.c-hero__title-mi {
		opacity: 0;
		visibility: hidden;
		animation: flyInAndOut 5s forwards;
		position: absolute;
		width: 100%;
		transition: all ease 0.3s;

		@include bp("large") {
			white-space: pre-wrap;
			//top: 0;
		}
	}


	.c-hero__title-eng {
		opacity: 1;
		display: block;
		visibility: visible;
	}

	.c-hero__title-mi + .c-hero__title-eng {
		animation: flyIn 5s forwards;
		transition: all ease 0.3s;
	}

	.c-hero__button {
		align-self: flex-start;
	}

	.c-content-type-ruakurainlandporthomepage &.c-hero--no-golden-triangle {
		@include bp("large") {
			// Info panel overflows by $hsu
			margin-bottom: $hsu + $lsu;
		}
	}
}

.c-content-type-ruakurainlandporthomepage {
	@include bp("large") {
		.c-hero--homepage.c-hero--no-golden-triangle {
			// Move space from hero margin to main padding, so scrolling won't be flush
			margin-bottom: 0;

			+ .o-layout-main {
				padding-top: 120px;
			}
		}
	}
}

.c-hero__body-icon_wrapper {
	display: none;

	@include bp("medium") {
		display: block;
		width: 100%;
		position: absolute;
		bottom: ($bsu * 3);
		text-align: left;

		@include bp-below("large") {
			.c-content-type-ruakurainlandporthomepage & {
				display: none;
			}
		}
	}

	@include bp("large") {
		bottom: 0;
	}

	@include bp("extra-large") {
		text-align: center;
	}
}

.c-hero__body-icon {
	display: inline-block;
	margin-right: ($bsu * 3);
	font-size: 50px;
	cursor: pointer;
	position: relative;
	z-index: 1;
}

.c-hero__body-pods-list-item {
	width: 100%;

	.c-hero-pod__item-body {
		padding-bottom: ($bsu * 1.5);
		padding-top: ($bsu + $tsu);
		background-position: right 109%;

		@include bp("medium") {
			padding-top: $lsu;
			padding-bottom: ($lsu * 2);
		}
	}
}

.c-hero-pod__item {
	&:hover,
	&:focus {
		.theme__dark {
			.c-hero-pod__item-date,
			.c-hero-pod__item-heading,
			.c-hero-pod__item-heading-text {
				color: var(--primary-b);
			}
		}
	}

	.c-hero-pod__item-heading {
		@include font-heading-16b;
		padding-right: 0;

		@include bp("medium") {
			padding-right: calc(1em + 5px);
		}


		@include bp($news-listing-bp) {
			@include font-heading-20b;
		}
	}

	.c-news-listing__item-date {
		@include font-heading-12;
		margin-top: $ssu;

		@include bp($news-listing-bp) {
			@include font-heading-14;
			margin-top: 0;
		}
	}
}



@keyframes flyInAndOut {
	0% {
		opacity: 1;
		visibility: visible;
	}

	85% {
		opacity: 1;
		visibility: visible;
	}

	90% {
		opacity: 0;
		visibility: hidden;
	}

	100% {
		opacity: 0;
		visibility: hidden;
	}
}

@keyframes flyIn {
	0% {
		opacity: 0;
		visibility: hidden;
	}

	89% {
		opacity: 0;
		visibility: hidden;
	}

	90% {
		opacity: 1;
		visibility: visible;
	}

	100% {
		opacity: 1;
		visibility: visible;
	}
}


.c-hero__slides {
	flex-grow: 1;
	position: relative;

	@include bp-below($hero-bp) {
		padding-top: 350px;
		padding-top: unquote("min(75%, 80vh)");
	}
}

.c-hero__slide {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	.c-hero__figure {
		height: 100%;
		max-height: none;
	}

	visibility: hidden;
	opacity: 0;

	&[aria-current="true"] {
		visibility: visible;
		opacity: 1;
	}

	&.allow-animations {
		animation: 0.5s fadeout forwards, 0.5s becomeHidden forwards;

		&[aria-current="true"] {
			animation: 0.5s fadein forwards, 0.5s becomeVisible forwards;
		}
	}
}

.c-hero__slide-body {
	position: absolute;
	bottom: 0;
	left: 0;

	@supports (background-color: var(--supporting-1-a)) {
		--hero__slide-body-border-width: 8px;

		@include bp($hero-bp) {
			--hero__slide-body-border-width: 16px;
		}

		&::before {
			content: "";
			position: absolute;
			top: 0;
			height: 100%;
			left: calc(-1 * var(--hero__slide-body-border-width));
			width: var(--hero__slide-body-border-width);
			background-color: var(--supporting-1-a);
		}

		@include bp-below($hero-bp) {
			padding-left: calc(#{$bsu} + var(--hero__slide-body-border-width));

			&::before {
				left: 0;
			}
		}
	}

	padding: $bsu;

	@include bp($hero-bp) {
		padding: $lsu $lsu $bsu;
	}

	width: calc(100% - #{$lsu});

	@include bp("medium") {
		width: $hero__slide-body-width;
	}
}

.c-hero__slide-title {
	display: block;
	margin-bottom: $bsu;

	@include font-heading-18b;

	@include bp($hero-bp) {
		margin-bottom: $lsu + $ssu;
		@include font-heading-28b;
	}
}

.c-hero__slide-link {
	@include font-heading-14b;

	@include bp($hero-bp) {
		@include font-heading-16b;
	}

	display: inline-block;
	margin-right: 160px;
	padding-bottom: $ssu;
	@include link-fancy(before);
	color: inherit;

	&::after {
		display: inline-block;
		vertical-align: middle;
		margin-left: $ssu;

		@include font-icon;
		content: $iconf-arrow-right;
	}
}

.c-hero__slide-buttons {
	position: absolute;
	bottom: 0;
	right: $lsu + $ssu;

	@include bp("medium") {
		right: calc(100% - #{$hero__slide-body-width - $ssu});
	}

	display: flex;
}

.c-hero__slide-button {
	@include bp-below($hero-bp) {
		@include button("medium", true);
	}

	@include bp($hero-bp) {
		@include button("large", true);
	}

	background: transparent;
	margin-left: $tsu;

	&:first-child {
		margin-left: 0;
	}

	&::before {
		@include font-icon;
	}
}

.c-hero__slide-button--prev {
	&::before {
		content: $iconf-arrow-left;
	}
}

.c-hero__slide-button--next {
	&::before {
		content: $iconf-arrow-right;
	}
}

