﻿$two-column-bp: "large";

.c-two-column {
	@include control;

	.c-rte + & {
		// 1.5em is the standard paragraph spacing
		@include bp-below($two-column-bp) {
			margin-top: calc(1.5em + -1 * var(--control-gap));
		}
		@include bp($two-column-bp) {
			margin-top: calc(1.5em + -1 * var(--control-gap));
		}
	}
}

.c-two-column__layout {
	@include layout-wrapper;
}

.c-two-column__layout-inner {
	@include flex-extend;
	flex-wrap: wrap;
	margin-left: -$lsu;

	// Subtract gutter on either side, but add this element's
	// negative margin back so it's included in its max-width
	//max-width: $layout-wrapper-narrow-width - $bsu * 2 + $lsu;
	//max-width: calc($layout-wrapper-narrow-width-vars - #{$bsu * 2 + $lsu});
}

.c-two-column__column {
	padding-left: $lsu;
	width: 100%;

	@include bp-below($two-column-bp) {
		+ .c-two-column__column {
			// 1.5em is the standard paragraph spacing
			margin-top: 1.5em;
		}
	}
	@include bp($two-column-bp) {
		width: 50%;
	}

	&:nth-child(n+1) {
		> :first-child {
			// Should always be a control
			margin-top: 0;
		}
	}
}
