﻿.c-tabs__link {
	&:focus,
	&:hover {
		color: $carcoal;
	}
}

.c-tabbed-content__eyebrow,
.c-tabbed-content__intro {
	color: $white;
}

.c-tabbed-content__layout-top.t-bg--sec.t-bg__text--sec,
.c-tabs__list {
	background-color: $carcoal;
}