﻿// OBJECT.POD

.o-pod {
	position: relative;
	display: block;
	height: 285px;
}

.o-pod__body {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	padding: 0.666em;
	text-align: center;
	color: $white;
}

.o-pod__heading {
	@include font-heading-20;
	position: absolute;
	left: 50%;
	top: 50%;
	width: calc(100% - #{$lsu});
	transform: translate(-50%, -50%);
}