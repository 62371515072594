﻿// MACRO.BLOCKQUOTE

.m-quote {
	&::before {
		background-color: $energy--dark-lime !important;
	}
}

.m-quote__text.t-text {
	color: $energy--dark-lime;
}

.m-quote__citation.t-text {
	color: $carcoal;
}
