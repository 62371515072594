// CONTROL.PEOPLE-LISTING

$people-listing-bp: "large";

.c-people-listing {}

.c-people-listing__layout {}

/////////////
// HEADING //
/////////////
.c-people-listing__heading {
	@include layout-wrapper;
}

//////////
// BODY //
//////////
.c-people-listing__body {
	background: $grey--lightest;
	.l-page--has-sidebar .l-page__main-controls & {
		background: transparent;
	}

	padding: $bsu 0;
	padding: calc(var(--control-gap) / 2) 0;
}

.c-people-listing__body-layout {
	@include layout-wrapper;
}

.c-people-listing__no-results {
	display: block;
	margin-top: $bsu;

	@include font-base-20;
}

//////////
// ITEM //
//////////
.c-people-listing {
	.c-people__item {
		@include link-transition;
		@include scroll-appear {
			opacity: 0;
			transform: translateY(100px);
		}
	}
}
