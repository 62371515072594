﻿// CONTROL.FORM

@use "sass:math";

.c-form {
	@include control;

	input[type="text"],
	input[type="password"],
	input[type="email"],
	input[type="tel"],
	select,
	textarea {
		@include form-input;
	}

	select ~ .field-validation-error {
		border-color: $error;
		margin-bottom: $tsu;
	}

	textarea {
		height: 150px;
	}
}

.c-form__layout {
	@include layout-wrapper;
}

.c-form__layout-inner {
	max-width: $layout-wrapper-narrow-width - $bsu * 2;
	max-width: calc($layout-wrapper-narrow-width-vars - #{$bsu * 2});
}

.c-form__heading {
	@include control__heading;
}

.c-form__form {
	background: $grey--lightest;
	--form-padding--inline: #{$bsu};
	--form-padding--block: #{$bsu};
	padding: $bsu $bsu;
	padding: var(--form-padding--block) var(--form-padding--inline);
	border-radius: 4px;
	position: relative;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: $bsu;
		left: var(--form-padding--inline);
		height: 5px;
		width: 100px;
	}

	@include bp("medium") {
		--form-padding--inline: #{$lsu};
		--form-padding--block: #{$lsu};
	}

	@include bp("large") {
		--form-padding--inline: #{$hsu};
	}
}

.c-form__field {
	margin-bottom: $bsu;

	&:last-child {
		margin-bottom: 0;
	}
}

.c-form__field-label {
	@include font-base-16;
}

.c-form__required-indicator {
	color: $red;
}

.c-form__controls {
	margin-top: $bsu;
}

.umbraco-forms-form {
}

.umbraco-forms-page {
	.umbraco-forms-caption {
		margin-bottom: 1.5em;
		@include font-heading-18b;
		font-size: 18px !important;
		margin-bottom: 1.5em !important;
		font-weight: normal;

		@include bp("medium") {
			@include font-heading-24b;
			font-size: 24px !important;
			line-height: 1.3 !important;
			font-weight: normal;
		}
	}

	.umbraco-forms-fieldset {
		.umbraco-forms-field {
			.umbraco-forms-field-wrapper {
				input,
				textarea {
					max-width: none !important;
					padding: 9px .65em !important;
					width: 100% !important;
				}
			}
		}
	}
}

.umbraco-forms-fieldset {
	@include ui-border('bottom');
	padding-bottom: $lsu;
	margin-bottom: $lsu;

	legend {
		padding-bottom: $bsu;
	}
}

.umbraco-forms-field {
	margin-bottom: $bsu;

	&:last-child {
		margin-bottom: 0;
	}

	&.checkbox {
		position: relative;
		padding-left: $bsu;

		input[type="checkbox"] {
			position: absolute;
			left: 0;
			top: $tsu;
		}
	}
}

.umbraco-forms-label-wrapper {
	order: 2;
}

.umbraco-forms-label, .c-form__label {
	@include font-base-16b;
	display: block;
}

.umbraco-forms-tooltip {
	@include font-base-14;
	color: $grey--dark;
}

.umbraco-forms-form .btn {
	@include button;

	@include bp("large") {
		@include button-size("large");
	}
}

.field-validation-error, .c-form__error-message, .validation-summary-errors {
	@include font-base-14;
	color: $error;
}

.c-form__error-message {
	display: none;
}

.has-error .c-form__error-message {
	display: block;
}

.umbraco-forms-field-wrapper {
	margin-top: $ssu;

	.date & {
		position: relative;

		@include bp("small") {
			max-width: 250px;
		}

		&:after {
			position: absolute;
			display: block;
			right: $ssu;
			top: 15px;
			font-family: #{$icomoon-font-family};
			content: $iconf-calendar;
			pointer-events: none;
		}
	}

	.checkbox & {
		margin-top: 0;
	}

	.singlecheckbox & {
		order: -1;
		margin-right: $tsu;
	}

	.titleanddescription & {
		margin-top: 0;
	}

	.checkboxlist, .radiobuttonlist {
		label {
			display: inline-block;
			margin-bottom: calc($ssu / 2);
			margin-left: 3px;
		}

		label:last-of-type {
			margin-bottom: 0;
		}
	}
}

.umbraco-forms-navigation {
	text-align: right;
}


.titleanddescription {

	h2 {
		@include font-heading-32;
		margin-bottom: 0.5em;
	}

	p {
		@include font-base-20;

		&:last-child {
			margin-bottom: 0;
		}
	}
}


// Fake inputs
.c-fake-checkbox {
	&:checked ~ .c-fake-checkbox__display {
		&::before {
			border-color: $blue;
		}
	}

	&:focus ~ .c-fake-checkbox__display {
		@include focus-outline;
	}
}

.c-fake-checkbox__display {
	@include link-transition;

	&::before {
		@include link-transition;
		content: '';
		position: absolute;
		left: 4px;
		top: 5px;
		display: block;
		width: 16px;
		height: 8px;
		border: 2px solid transparent;
		border-width: 0 0 2px 2px;
		background: transparent;
		transform: rotate(-45deg);
	}

	&.o-fake-input__display--small {
		&::before {
			left: 3px;
			top: 5px;
			width: 12px;
			height: 6px;
		}
	}
}

// Fake Radio
.c-fake-radio {
	&:checked ~ .c-fake-radio__display {
		&::before {
			background-color: $blue;
		}
	}

	&:focus ~ .c-fake-radio__display {
		@include focus-outline;
	}
}

.c-fake-radio__display {
	border-radius: 25px;

	@include link-transition;

	&::before {
		@include link-transition;
		content: '';
		position: absolute;
		left: 3px;
		top: 3px;
		display: block;
		width: 17px;
		height: 17px;
		border-radius: 17px;
		background-color: transparent;
	}
}
// Fake file
.c-fake-file {
}

.c-fake-file__display {
	@include flex-extend;

	.c-fake-file:focus + & {
		@include focus-outline;
	}

	&.has-error {
		.c-fake-file__display-value {
			border-color: $error;
		}

		.c-fake-file__display-button {
			background: $error;
		}
	}

	cursor: pointer;

	.c-fake-file:disabled + & {
		cursor: not-allowed;

		.c-fake-file__display-value {
			background: $grey--light;
		}

		.c-fake-file__display-button {
			cursor: not-allowed;
			color: $white;

			&,
			&:hover,
			&:focus {
				background-color: $grey;
			}
		}
	}
}

.c-fake-file__display-value {
	@include form-input;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	&:not(.has-value) {
		color: $grey;
	}
}

.c-fake-file__display-button {
	margin-left: $ssu;

	@include flex-extend;
	align-items: center;
	justify-content: center;
}
