// CONTROL.dual-CTA

$dual-cta-bp: 1640px;

.c-dual-cta {}

.c-dual-cta__layout {}

.c-dual-cta__item {
	@include flex-extend;
	@include bp-below("small") {
		flex-direction: column-reverse;
		align-items: center;
	}

	background: $grey--lightest;
	box-shadow: inset 0 0 0 1px $base-ui-color;
	.c-dual-cta__heading,
	.c-dual-cta__text {
		color: $black;
	}

	&.c-dual-cta__item--dark {
		box-shadow: none;
		background: $black;

		.c-dual-cta__heading,
		.c-dual-cta__text {
			color: $white;
		}
	}
}

.c-dual-cta__media {
	display: none;

	@include bp(500px) {
		@include flex-extend;
		flex-shrink: 0;
		width: 240px;
	}
}

.c-dual-cta__body {
	@include flex-extend;
	flex-grow: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: $lsu;
	text-align: center;

	*:last-child {
		margin-bottom: 0;
	}
}

.c-dual-cta__media + .c-dual-cta__body {

	@include bp(500px) {
		align-items: flex-start;
		text-align: left;
	}
}

.c-dual-cta__heading {
	display: block;
	margin-bottom: $bsu;

	@include font-heading-24b;
}

.c-dual-cta__text {
	@include font-base-16b;
	display: block;
	margin-bottom: $bsu;
}

.c-dual-cta__heading ~ .c-dual-cta__text {
	@include font-base-16;
}

.c-dual-cta__link {
	display: block;
}

@mixin dual-cta-large {

	.c-dual-cta__layout {
		@include flex-extend;
		align-items: stretch;
	}

	.c-dual-cta__item {
		width: 50%;
		&:nth-child(1):nth-last-child(1) {
			// Only child
			width: 100%;
		}

		+ .c-dual-cta__item {
			margin-top: 0;
		}
	}
}

@mixin dual-cta-small {
	.c-dual-cta__layout {
		display: block;
	}

	.c-dual-cta__item {
		width: 100%;

		+ .c-dual-cta__item {
			margin-top: $lsu;
		}
	}
}


// Uses different breakpoints on pages with a sidebar
@include dual-cta-small;

@include bp("large") {
	@include dual-cta-large;
}

.o-layout-content--has-aside {
	@include bp("large") {
		@include dual-cta-small;
	}
	@include bp($layout-wrapper-max-width) {
		@include dual-cta-large;
	}
}
