/* TOOLS.MIXINS */

@use "sass:math";

// Font Icons
@mixin font-icon {
	// use !important to prevent issues with browser extensions that change fonts
	font-family: '#{$icomoon-font-family}' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	// Better Font Rendering
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// Breakpoint mixin
// Uses $breakpoints variable set in _config.scss file
@mixin bp($size) {

	@if (type-of($size) == "number") {
		@media (min-width: $size) {
			@content;
		}
	} @else if (type-of($size) == "string") {
		@each $breakpoint in $breakpoints {
			// Get the name of the breakpoint.
			$alias: nth($breakpoint, 1);

			@if ($size == $alias) {
				$start: bp-config-value($breakpoint, "start");

				@media (min-width: $start) {
					@content;
				}
			}
		}
	} @else {
		@warn "Unfortunately, no value could be retrieved from '#{$size}'. Please make sure it is defined in '$breakpoints' map.";
	}
}

@mixin bp-below($size) {

	@if (type-of($size) == "number") {
		@media (max-width: #{em($size - 1px)}) {
			@content;
		}
	} @else if (type-of($size) == "string") {
		@each $breakpoint in $breakpoints {
			// Get the name of the breakpoint.
			$alias: nth($breakpoint, 1);

			@if ($size == $alias) {
				$start: bp-config-value($breakpoint, "start");

				@media (max-width: #{$start - 1px}) {
					@content;
				}
			}
		}
	} @else {
		@warn "Unfortunately, no value could be retrieved from '#{$size}'. Please make sure it is defined in '$breakpoints' map.";
	}
}

// Positioning
@mixin abs-center {
	position: absolute;
	right: 50%;
	top: 50%;
	transform: translate(50%, -50%);
}

@mixin abs-center-h {
	position: absolute;
	right: 50%;
	transform: translateX(50%);
}

@mixin abs-center-v {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}


// Layout
@mixin layout-wrapper--flush($width: $layout-wrapper-max-width) {
	max-width: $width;
	margin: 0 auto;
	flex-grow: 1;
}

@mixin layout-wrapper($width: $layout-wrapper-max-width) {
	@include layout-wrapper--flush($width);
	padding: 0 $bsu;
	padding: 0 var(--layout-gutter-right) 0 var(--layout-gutter-left);

	> * {
		--layout-gutter-left: 0;
		--layout-gutter-right: 0;
	}
}

@mixin layout-wrapper--narrow {
	@include layout-wrapper($layout-wrapper-narrow-width);
	max-width: $layout-wrapper-narrow-width-vars;
}

@mixin layout-split($side, $split, $width: $layout-wrapper-max-width, $gutter: $bsu) {
	// $side should be either left or right
	// $split should be a percentage

	// Example use:
	// @include layout-split(left, 40%);

	// To be used elements that need to extend to the edge of the page,
	// so it can't be wrapped in a layout-wrapper, but with content that
	// needs to align with indented content as though it were in a wrapper.

	// Because it uses % padding, it's important that any element using this
	// sits within a container with a specified width. That width should be
	// the same as $split as a proportion of the screen width

	$counter-split: 100% - $split;
	$ratio: calc($counter-split / $split);

	$growthRate: #{(calc(($ratio - 1) / 2) + 1) * 100%};
	$normaliser: #{calc($width / 2) - $gutter};

	@include bp($width) {
		padding-#{$side}: calc(#{$growthRate} - #{$normaliser});
	}
}

/**
 * The "RAM" pattern is Repeat Auto Minmax, which allows items in a grid
 * to reflow automatically with the goal of putting as many items as
 * possible on a row while also never going below a minimum size
 */
@mixin grid-ram($size) {
	grid-template-columns: repeat(auto-fit, minmax($size, 1fr));
}
// Transition
@mixin link-transition($prop: all, $speed: 0.25s) {
	transition: $prop $speed ease-in-out;

	@media (prefers-reduced-motion) {
		transition: none;
	}
}

@mixin scroll-appear {
	@media screen and (prefers-reduced-motion: no-preference) {
		.js &:not([data-scroll-appear="true"]) {
			@content;
		}
	}
}

// Clearfix
@mixin clearfix {
	&::after {
		content: "";
		display: table;
		clear: both;
	}
}

@mixin remove-clearfix {
	&::after {
		display: none;
		clear: none;
	}
}

// Pseudo Element
@mixin pseudo-element {
	content: '';
	display: block;
	position: absolute;
}

// Reliable Flexbox fallback mixin
// 1. Unsupported
// 2. Old - iOS 6-, Safari 3.1-6
// 3. Old - Firefox 19- (buggy but mostly works)
// 4. Tweener - IE 10
// 5. New - Chrome
// 6. New, Spec - Opera 12.1, Firefox 20+
@mixin flex-extend {
	display: block;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	/* autoprefixer: ignore next */
	display: flex;
}

@mixin flex-wrap {
	-webkit-flex-flow: row wrap;
	-ms-flex-flow: row wrap;
	/* autoprefixer: ignore next */
	flex-wrap: wrap;
}

@mixin flex-nowrap {
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	/* autoprefixer: off */
	flex-wrap: nowrap;
}

// Placeholder Text
@mixin placeholder-text ($color) {
	&::-webkit-input-placeholder {
		color: $color;
	}
	&:-moz-placeholder {
		color: $color;
	}
	&:-ms-input-placeholder {
		color: $color;
	}
}

// Mixin to add square dimensions to an element i.e. the same width and height
// Example: Create a square 40em high and wide
// @include tb_square ( 40em );
@mixin square($size) {
	width: $size;
	height: $size;
}

// Mixin to add circle dimensions to an element i.e. adds square dimensions and border-radius of (dimensions / 2)
// Example: Create a circle 40em high and wide
// @include tb_square ( 40em );
@mixin circle($size) {
	@include square($size);
	border-radius: calc($size / 2);
}

// UI Border mixin
$ui-border: 1px solid $base-ui-color;

@mixin ui-border($side) {
	@if ($side == 'all') {
		border: $ui-border
	} @else if ($side == 'top') {
		border-top: $ui-border;
	} @else if ($side == 'right') {
		border-right: $ui-border;
	} @else if ($side == 'bottom') {
		border-bottom: $ui-border;
	} @else if ($side == 'left') {
		border-left: $ui-border;
	}
}

// Resets
@mixin button-reset {
	// Used to reset styles on <button>s, not necessarily for .o-btn elements
	// This can be used for interactive elements that aren't links
	background: transparent;
	border: none;
	border-radius: 0;
	padding: 0;
	cursor: pointer;
}

@mixin list-reset {
	padding: 0;
	margin: 0;
	list-style: none;
}

@mixin link-plain {
	@include link-transition;
	color: inherit;
}

@mixin link-fancy__hover-parent($offset: 0px) {
	--link-fancy__opacity: 0;
	--link-fancy__width: 0;

	&:hover,
	&:focus-visible {
		--link-fancy__opacity: 1;
		--link-fancy__width: calc(100% - #{$offset});
	}
}

@mixin link-fancy($pseudo: after, $offset: 0px) {
	position: relative;
	&::#{$pseudo} {
		content: "";
		position: absolute;
		left: $offset;
		bottom: 0;

		height: 2px;

		width: 0;
		width: var(--link-fancy__width, 0);
		opacity: 0;
		opacity: var(--link-fancy__opacity, 0);

		background-color: var(--primary-a);

		@include link-transition;
	}

	&:hover,
	&:focus-visible {
		text-decoration: underline;

		@supports (background-color: var(--secondary-a)) {
			text-decoration: none;

			&::#{$pseudo} {
				width: calc(100% - #{$offset});
				width: var(--link-fancy__width, calc(100% - #{$offset}));
				opacity: 1;
				opacity: var(--link-fancy__opacity, 1);
			}
		}
	}
}

@mixin link-arrow() {
	position: relative;

	&::after {
		@include font-icon;
		content: $iconf-arrow-right;

		display: inline-block;
		vertical-align: middle;
		margin-left: 0.5em;

		position: absolute;
		bottom: 0.2em;
		right: -$tsu;
		transform: translateX(100%);

		opacity: 0;
		@include link-transition(opacity);
	}
}

@mixin list-inline {
	> li {
		display: inline;
	}

	> [data-divider] {
		&::after {
			content: attr(data-divider);
		}
		&:last-child {
			&::after {
				display: none;
			}
		}
	}
}

@mixin list-stacked {
	> li {
		&:last-child {
			margin-bottom: 0;
		}
	}
}

// Forms
@mixin form-input {
	// iOS zooms in unless font size in inputs is at least 16px
	@include font-base-16;

	display: block;
	width: 100%;

	// 9px top and bottom padding makes them 44px tall, same as buttons
	padding: 9px 0.65em;

	border: 1px solid $grey;
	border-radius: 4px;

	box-shadow: inset 0 1px 3px 0 rgba($black, 0.2);

	&.input-validation-error {
		border-color: $error;
		margin-bottom: $tsu;
	}
}

$outline-default: #4d90fe;
@mixin focus-outline($color: $outline-default) {
	// Replicate Chrome's default focus style
	outline-color: $color;
	@if ($color == $outline-default) {
		@supports (outline-color: -webkit-focus-ring-color) {
			outline-color: -webkit-focus-ring-color;
		}
	}

	// IE11 and Edge don't support outline-style: auto
	outline-style: solid;
	outline-width: 2px;
	@supports(outline-style: auto) {
		outline-style: auto;
	}
}

//Folded corner
@mixin folded-corner ($bgColor: var(--primary-a), $width: 40px, $height: 40px) {
	position: relative;

	&::before {
		content: '';
		display: block;
		width: $width;
		height: $height;
		position: absolute;
		top: 0;
		right: 0;
		background-color: $bgColor;
		z-index: 3;
		box-shadow: 0 0 5px rgba($black , 0.3) ;
	}

	&::after {
		content: '';
		position: absolute;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 $width $height 0;
		border-color: transparent $white transparent transparent;
		top: 0;
		right: 0;
		z-index: 3;
	}
}