// CONTROL.LATEST-INSIGHTS

$latest-insights-bp: "large";

.c-latest-insights {
	@include control;
	padding: $lsu 0;
	padding: var(--control-gap) 0;

	background: $grey--lightest;
}

.c-latest-insights__header {
	@include layout-wrapper;

	@include bp-below($latest-insights-bp) {
		margin-bottom: $bsu;
		.c-latest-insights__buttons {
			display: none;
		}
	}
	@include bp($latest-insights-bp) {
		display: flex;
		align-items: center;
		margin-bottom: $lsu;
	}
}

.c-latest-insights__body {
	@include layout-split("left", 100%);
	overflow: hidden;
}

.c-latest-insights__footer {
	@include layout-wrapper;
	@include bp($latest-insights-bp) {
		display: none;
	}
}

////////////
// HEADER //
////////////
.c-latest-insights__heading {
	@include control__heading;
}

.c-latest-insights__heading-mi {
	@include font-heading-13i;
	display: inline-block;
	margin-bottom: $tsu;
}

// Use separate block to ensure overrides apply correctly
.c-latest-insights__heading {
	display: block;
	margin-right: $lsu;
	margin-bottom: 0;

	@include bp-below($latest-insights-bp) {
		margin-bottom: $ssu;
	}
}

.c-latest-insights__links {
	@include flex-extend;
}

.c-latest-insights__link {
	display: flex;
	align-items: center;
	padding-top: $tsu;
	padding-bottom: $ssu;
	margin-top: ($bsu * 2);

	@include font-heading-14b;
	@include bp($latest-insights-bp) {
		@include font-heading-16b;
	}

	margin-right: $lsu;
	&:last-child {
		margin-right: 0;
	}

	@include link-fancy(before);
	&::after {
		@include font-icon;
		content: $iconf-arrow-right;

		display: inline-block;
		margin-left: $ssu;
	}
}

.c-latest-insights__buttons {
	@include bp($latest-insights-bp) {
		.c-latest-insights__header & {
			flex-grow: 1;
			display: flex;
			justify-content: flex-end;
		}
	}
}

.c-latest-insights__button {
	margin-right: $bsu;
	&:last-child {
		margin-right: 0;
	}

	@include bp($latest-insights-bp) {
		@include button("medium", true);
	}
}

//////////
// BODY //
//////////
.c-latest-insights__rotator {
	display: flex;
	margin-left: -$lsu;
	@include bp-below($latest-insights-bp) {
		margin-left: $ssu - $lsu;
	}

	position: relative;
	&::before {
		content: "";
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		height: 100%;
		width: $lsu;

		background: linear-gradient(90deg, rgba($grey--lightest, 1), rgba($grey--lightest, 0));
	}

	&.flickity-enabled {
		display: block;
		.flickity-viewport[style*="height:"] .c-latest-insights__rotator-item {
			height: 100%;
		}
	}
}

.c-latest-insights__rotator-item {
	padding-left: $lsu;
	opacity: 0.5;

	&.is-selected,
	&.is-selected + .c-latest-insights__rotator-item,
	&:hover,
	&:focus {
		opacity: 1;
	}

	&.is-selected + .c-latest-insights__rotator-item {
		+ .c-latest-insights__rotator-item {
			@include bp($latest-insights-bp) {
				opacity: 1;
			}
		}
	}
}


.c-latest-insights__pod {
	height: 100%;

	display: flex;
	flex-direction: column;

	width: 260px;
	width: clamp(260px, 33vw, 415px);

	&:hover,
	&:focus {
		.c-latest-insights__pod-image {
			transform: scale(1.1);
		}

		.c-latest-insights__pod-title-text::after {
			opacity: 1;
		}
	}

	position: relative;
	isolation: isolate;
}

.c-latest-insights__pod--no-image {
	.c-latest-insights__pod-body {
		padding-top: $lsu;
		padding-bottom: $hsu;
		background-image: url("/content/images/interface/bg/motifSmallGrey.svg");
		background-repeat: no-repeat;
		background-position: bottom right;
		background-size: 60%;

		&.theme__light {
			background-image: url("/content/images/interface/bg/motifSmallRed.svg");
		}

		&.theme__gold {
			background-image: url("/content/images/interface/bg/motifSmallGold.svg");
		}
	}
}

.c-latest-insights__pod-tag {
	position: absolute;
	z-index: 1;
	top: 0;
	right: 0;

	padding: $tsu $ssu;
	border-radius: 0;

	@include font-base-12bu;
}

.c-latest-insights__pod-figure {
	@include figure;
	overflow: hidden;
}

.c-latest-insights__pod-image {
	@include link-transition;
}

.c-latest-insights__pod-body {
	flex-grow: 1;
	padding: $bsu;

	@include link-transition(color);
}

.c-latest-insights__pod-date {
	display: block;
	margin-bottom: $ssu;

	@include bp-below($latest-insights-bp) {
		@include font-base-12b;
	}
	@include bp($latest-insights-bp) {
		@include font-heading-14b;
	}
}

.c-latest-insights__pod-title {
	display: inline-block;
	padding-right: calc(1em + #{$tsu});

	@include bp-below($latest-insights-bp) {
		@include font-heading-18b;
	}
	@include bp($latest-insights-bp) {
		@include font-heading-22b;
	}
}

.c-latest-insights__pod-title-text {
	@include link-arrow;
}

.c-latest-insights__pod-text {
	display: block;
	margin-top: $bsu;

	@include body-text;
}

////////////
// FOOTER //
////////////
.c-latest-insights__footer {
	.c-latest-insights__buttons {
		display: flex;
		justify-content: space-between;
		margin-top: $lsu;
	}

	.c-latest-insights__button {
		@include button("small", true);
	}
}
