// CONTROL.LISTING-STACKED

@use "sass:math";

$listing-stacked-bp: "medium";

.c-listing-stacked__item {
	@include bp-below($listing-stacked-bp) {
		display: block;
	}
	@include bp($listing-stacked-bp) {
		display: flex;
		align-items: flex-start;
	}

	@include ui-border("all");
	border-radius: 0;

	@include link-transition;
}

.c-listing-stacked__heading {
	display: block;

	flex-shrink: 0;
	width: 100%;
	padding: $bsu;
	@include bp($listing-stacked-bp) {
		padding: $lsu;
		width: percentage(calc(1 / 3));
	}

	@include font-heading-20b;
}

.c-listing-stacked__text {
	display: block;

	@include bp-below($listing-stacked-bp) {
		padding: $bsu;
		padding-top: 0;
	}
	@include bp($listing-stacked-bp) {
		padding: $lsu;
		padding-left: 0;
	}

	flex-grow: 1;

	@include font-body-text;
	color: $body-color;
}

.c-listing-stacked__figure {
	@include figure;

	flex-shrink: 0;
	width: 100%;
	@include bp($listing-stacked-bp) {
		width: percentage(calc(1 / 3));
	}
}

.c-listing-stacked__image {}
