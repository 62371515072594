﻿.c-basic-filter__item .t-action--outline {
	box-shadow: inset 0 0 0 1px $carcoal;
	color: $carcoal;
	background: none;

	&:hover,
	&:focus {
		box-shadow: inset 0 0 0 1px $energy--dark-lime;
		color: $energy--dark-lime;
		background: none;
	}
}

.c-news-listing__item-body.t-bg--rev.t-text--sec.theme__light {


	.c-news-listing__item--no-image & {
		background-color: $carcoal;
		color: $white;
		background-image: url("/content/images/interface/bg/motifSmallGreen.svg") !important;
	}

	.c-news-listing__item--has-image & {
		background-color: transparent;
		color: $carcoal;
		box-shadow: 0px 5px 8px 5px rgba(0, 0, 0, 0.10);
	}
}

.c-news-listing__item-tag {
	font-weight: $weight--bold;
}

.c-listing-stacked__heading.t-text {
	color: $carcoal;
}